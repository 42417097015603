import React, { useState, useEffect } from 'react'
import { YhOfferPrint } from "../YhOfferPrint/offerPrint"
import { saleRoService } from './saleRoService';
import { ISaleRoDetail, IDetailList } from "./saleRoTypes"
import { numberParseChina, momentFormat } from '@/utils/utils';
import { fmoney } from "../../utils";
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";
import { SettleType } from "../../dictMap";

export function CiSaleReturnPrint() {
    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const { user: { shopName, shopId, shopCode } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState("")
    const [formVal, setFormVal] = useState<ISaleRoDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IDetailList[]>([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState(initDesc);
    const [itemTotal, setItemTotal] = useState(initDesc);
    const [total, setTotal] = useState(totalDesc);
    //const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { id, code, ciSaleReturnPV, setCiSaleReturnPV } = usePrintModel()


    useEffect(() => {
        ciSaleReturnPV && getPoDetailOne()
    }, [ciSaleReturnPV])
    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await saleRoService.one(code);
        let { details, settlementPartyCode, customerName, ...formVal } = retData;
        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }
        let newdetails = details?.sort((a, b) => a.materialCode?.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a, b) => a.shelfCode?.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => {
            newMaintenanceTotal.total += Number(item.num) * Number(item.price)
            newTotal.num += Number(item.num)
            let { amount } = item
            return {
                ...item,
                No: index + 1,
                price: fmoney(item.price, 2),
                notAmount: Number(item.num) * Number(item.price),
                amount: fmoney(amount, 2)
            }
        })
        // if (settlementPartyCode === "settle-party.internal") {
        //     setSettlementPartyName(shopName)
        // } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
        //     let { value: customerCode } = getTreeNodeOne(settlementPartyCode)[0]
        //     setSettlementPartyName(getFindItem(customerCode, CustomerData).name)
        // } else {
        //     setSettlementPartyName(customerName )
        // }
        if (retData) {
            setMaintenanceList(newdetails ?? []);
            setFormVal({ ...formVal, settlementPartyCode, customerName })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };
    const printNum = async () => {
        await saleRoService.printNum(id);
        //await loadData()
    }
    const onCancel = () => {
        setCiSaleReturnPV(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
        {
            title: '销售单号',
            dataIndex: 'saleCode',
        }
    ]
    const XJHeader = [
        {
            fieldLabel: "车牌号：",
            fieldValue: formVal?.licensePlate
        },
        {
            fieldLabel: "底盘号：",
            fieldValue: formVal?.vin
        },
        {
            fieldLabel: "结算方式：",
            fieldValue: SettleType.get(formVal.payType ?? "")
        },
        {
            fieldLabel: "结算方：",
            className: "li-right",
            fieldValue: settlementPartyName,
        },
    ]
    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "销退单号:",
                fieldValue: formVal?.returnCode,
                className: "w30"
            },
            {
                fieldLabel: "客户名称:",
                fieldValue: formVal?.customerName,
            },
            {
                fieldLabel: '打印日期：',
                fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                //className: "li-right"
            },
            {
                fieldLabel: "销售单号:",
                fieldValue: maintenanceList?.[0]?.saleCode ?? '',
                className: "w30"
            },
            {
                fieldLabel: "联系人：",
                fieldValue: formVal?.contact
            },
            {
                fieldLabel: "销售日期：",
                fieldValue: momentFormat(formVal?.createTime ?? ""),
                className: "li-right"
            },
            {
                fieldLabel: "单据性质：",
                fieldValue: "销售退货单",
                className: "w30"
            },
            {
                fieldLabel: "电话：",
                fieldValue: formVal?.phone
            },
            {
                fieldLabel: "",
                fieldValue: "",
                className: "li-right"
            },


        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser
            },
            {
                // fieldLabel: "销售：",
                fieldLabel: "",
                fieldValue: ""
            },
            {
                // fieldLabel: "出纳：",
                fieldLabel: "",
                fieldValue: ""
            },
            // {
            //     fieldLabel: "仓库：",
            //     fieldValue: maintenanceList[0]?.warehouseName
            // },
            {
                fieldLabel: "客户签字：",
                fieldValue: ""
            },
            // {
            //     fieldLabel: "会计：",
            //     fieldValue: ""
            // },

        ],
        note: {
            fieldLabel: "备注:",
            fieldValue: formVal?.note
        },
        Total: {

            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.receivedAmount ?? 0),
            receivedAmount: fmoney(formVal?.receivedAmount, 2),
            label: "应付金额"
        },
    }
    const headerTitle = {
        // title: "天津欧豪富港汽车贸易有限公司销售退货单"
        title: `${formVal.bentityName}销售退货单`

    }
    return (
        <YhOfferPrint
            title="销售退货单打印详情"
            type=""
            visible={ciSaleReturnPV}
            onClose={onCancel}
            printNum={printNum}
            columns={columns}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printShow={printShow}
            headerTitle={headerTitle}
            onConfirm={() => null}
            itemClumns
            itemDataSource
        />
    )
}

import {
    ItemDetailed,
    MaterialDetailed,
    IChargebackField,
    IMaintenanceRoSettleParams,
    IChargebackSearch,
    IChargebackDetail,
    IChargebackRoSettleAndWhConfirm,
    IChargebackList,
    IPrintExcel,
    IChargebackStatistics
} from "./chargebackType"
import { httpRequest } from "@/utils/HttpRequest";

class ChargebackService {
    async page(searchVo: IChargebackSearch) {
        const apiUrl = "/vrm2/repair/return/findPage";
        return await httpRequest.post<IChargebackList>(apiUrl, searchVo);
    }
    async total(searchVo: IChargebackSearch) {
        const apiUrl = "/vrm2/maintenance/total";
        return await httpRequest.post<IChargebackStatistics>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/vrm2/repair/return/findById/${id}`;
        return await httpRequest.get<IChargebackDetail>(apiUrl);
    }
    async insert(insertVo: IChargebackField) {
        const apiUrl = "/vrm2/maintenance/insert";
        return await httpRequest.post<IChargebackDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IChargebackField) {
        const apiUrl = "/vrm2/repair/return/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //维修退单导出
    async export(params: IChargebackSearch) {
        const apiUrl = "/vrm2/repair/return/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    // 库房确认
    async warehousing(params: IChargebackRoSettleAndWhConfirm) {
        const apiUrl = "/vrm2/repair/return/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //结算
    async settl(params: IMaintenanceRoSettleParams) {
        const apiUrl = "/vrm2/repair/return/settlePro";
        // const apiUrl = "/vrm2/repair/return/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //作废
    async invalidate(params: object) {
        const apiUrl = "/vrm2/repair/return/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //重新加载维修项目得数据
    async projectQuery(params: string[]) {
        const apiUrl = "/vrm2/base/item/projectQuery";
        return await httpRequest.post<ItemDetailed[]>(apiUrl, params);
    }
    //重新加载维修用料得数据
    async find(params: string[]) {
        const apiUrl = "/vrm2/stock/find";
        return await httpRequest.post<MaterialDetailed[]>(apiUrl, params);
    }
    //预约转维修
    async transfer(params: object) {
        const apiUrl = "/vrm2/maintenance/transfer";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //转车间
    async tran(params: object) {
        const apiUrl = "/vrm2/maintenance/tran";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: IChargebackSearch) {
        const apiUrl = "/vrm2/repair/return/repairFormExport";
        // const apiUrl = "/vrm2/repair/return/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //打印Excel格式
    async excelPrint(params: IPrintExcel) {
        const apiUrl = "/vrm2/maintenance/exportFind";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印预约单Excel格式
    async appointmentExcelPrint(params: IPrintExcel) {
        const apiUrl = "/vrm2/appointment/exportFind";
        return await httpRequest.postFile(apiUrl, params);
    }
    //打印次数
    async printNum(id: string) {
        const apiUrl = `/vrm2/repair/return/print/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //审核接口
    async examine(params: object) {
        const apiUrl = `/vrm2/maintenance/examine`;
        return await httpRequest.post(apiUrl, params);
    }
    //审核通过接口
    async approved(params: object) {
        const apiUrl = `/vrm2/maintenance/approved`;
        return await httpRequest.post(apiUrl, params);
    }
}
export const chargebackService = new ChargebackService();

import { useState } from 'react'
import { createModel } from 'hox'

export const usePrintModel = createModel(function () {

    const [id, setId] = useState<string>("");
    const [code, setCode] = useState<string>("");

    const [scmSalePV, setScmSalePV] = useState<boolean>(false);
    const [scmPurcharseReturnPV, setScmPurcharseReturnPV] = useState<boolean>(false);

    const [bcmSalePV, setBcmSalePV] = useState<boolean>(false);
    const [bcmPurcharseReturnPV, setBcmPurcharseReturnPV] = useState<boolean>(false);
    const [bcmPresalePV, setBcmPresalePV] = useState<boolean>(false);

    const [vrmSalePV, setVrmSalePV] = useState<boolean>(false);
    const [vrmPurcharseReturnPV, setVrmPurcharseReturnPV] = useState<boolean>(false);
    const [vrmMaintenancePV, setVrmMaintenancePV] = useState<boolean>(false);

    const [ciSalePV, setCiSalePV] = useState<boolean>(false);
    const [ciPurcharseReturnPV, setCiPurcharseReturnPV] = useState<boolean>(false);
    const [ciMaintenancePV, setCiMaintenancePV] = useState<boolean>(false);

    return {
        id, setId,
        code, setCode,
        scmSalePV, setScmSalePV,
        scmPurcharseReturnPV, setScmPurcharseReturnPV,
        bcmSalePV, setBcmSalePV,
        bcmPurcharseReturnPV, setBcmPurcharseReturnPV,
        bcmPresalePV, setBcmPresalePV,
        vrmSalePV, setVrmSalePV,
        vrmPurcharseReturnPV, setVrmPurcharseReturnPV,
        vrmMaintenancePV, setVrmMaintenancePV,
        ciSalePV, setCiSalePV,
        ciPurcharseReturnPV, setCiPurcharseReturnPV,
        ciMaintenancePV, setCiMaintenancePV,
    }
})

import React, { useState, useEffect } from 'react'
// import "./offerPrint.css"
import { YhOfferPrint } from "../OfferPrint/OfferPrint"
import { busBusinesstService } from './busBusinessService';
import { ISaleDetailsList, ISaleDetail } from "./busBusinessType";
import { numberParseChina, formatNum, momentFormat, bigNumber } from '@/utils/utils';
import { fmoney } from "../../utils";
import { useDictModel } from '@/views/base/dict/dictModel';
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
import { usePrintModel } from "@/views/receipt/outWarehouse/view/printModel";

export type IMaintenanceAndItemType = {
    total: number
    discount: number
}
export type TotalType = {
    num: number,
    total: number
}
export function BcmPresalePrint() {
    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const { CustomerData, user: { shopName, shopId, shopCode } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState<string>("")
    const [formVal, setFormVal] = useState<ISaleDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<ISaleDetailsList[]>([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [itemTotal, setItemTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [total, setTotal] = useState<TotalType>(totalDesc);
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { code, bcmPresalePV, setBcmPresalePV } = usePrintModel()

    // const { shopAndOrg: { shopCode } } = useGlobalModel()

    useEffect(() => {
        bcmPresalePV && getPoDetailOne()
    }, [bcmPresalePV])
    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await busBusinesstService.preSaleOne(code);
        let { item, settlementPartyCode, customerName, ...formVal } = retData;

        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }

        let newdetails: any = item?.map((item, index) => {
            //newMaintenanceTotal.total += Number(item.num) * Number(item.price)
            newMaintenanceTotal.total = bigNumber.add(newMaintenanceTotal.total, bigNumber.toFixed(bigNumber.times(Number(item.num), Number(item.price)), 2))
            newMaintenanceTotal.discount = item.discountRate as number
            // newTotal.num += Number(item.num)
            newTotal.num = bigNumber.add(newTotal.num, Number(item.num))
            let { price, discountRate, amount } = item

            return {
                ...item,
                No: index + 1,
                // notAmount: Number(item.num) * Number(item.price),
                notAmount: bigNumber.toFixed(bigNumber.times(Number(item.num), Number(item.price)), 2),
                //taxPrice: fmoney(formatNum(Number(price) * Number(discountRate) / 100), 2),
                taxPrice: fmoney(bigNumber.toFixed(bigNumber.dividedBy(bigNumber.times(price as number, discountRate as number), 100), 2), 2),
                amount: fmoney(amount, 2)
            }
        })

        // if (settlementPartyCode === "settle-party.internal") {
        //     setSettlementPartyName(shopName)
        // } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
        //     let { value: customerCode } = getTreeNodeOne(settlementPartyCode as string)[0]
        //     // console.log(settlementPartyCode, getTreeNodeOne(settlementPartyCode as string),getFindItem(customerCode, CustomerData))
        //     setSettlementPartyName(getFindItem(customerCode, CustomerData).name)
        // } else {
        //     setSettlementPartyName(customerName as string)
        // }
        //这里做最后一行汇总信息展示
        // newdetails?.push({
        //     num: newTotal.num,
        //     No: "",
        //     materialCode: "合计",
        //     amount: fmoney(formVal.totalAmount, 2)
        // })
        if (retData) {
            setMaintenanceList(newdetails as ISaleDetail[] ?? []);
            setFormVal({ ...formVal, settlementPartyCode, customerName, })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };

    const onCancel = () => {
        setBcmPresalePV(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        // {
        //     title: '折扣',
        //     dataIndex: 'discountRate',
        //     render: (text: any) => text + "%"
        // },
        // {
        //     // title: '折后单价 Discounted Price',
        //     title: '单价 Unit price',
        //     dataIndex: 'taxPrice',
        // },
        {
            title: '金额',
            dataIndex: 'amount',
        },
    ]
    const XJHeader = [
        {
            fieldLabel: "车牌号：",
            fieldValue: formVal?.licensePlate
        },
        {
            fieldLabel: "底盘号：",
            fieldValue: formVal?.vin
        },
        {
            fieldLabel: "结算方式：",
            fieldValue: getTreeNodeName(formVal.payType as string)
        },
        {
            fieldLabel: "结算方：",
            className: "li-right",
            fieldValue: settlementPartyName,
        },
    ]
    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "预售单号：",
                fieldValue: formVal?.orderCode,
                className: "w30"
            },
            {
                fieldLabel: "客户名称：",
                fieldValue: formVal?.customerName,
            },
            {
                fieldLabel: "",
                fieldValue: "",
                className: "w20"

            },
            {
                fieldLabel: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                fieldValue: "",
                className: "li-right"
            },
            {
                fieldLabel: "联系人：",
                fieldValue: formVal?.contact
            },
            {
                fieldLabel: "电话：",
                fieldValue: formVal?.phone
            },
            {
                fieldLabel: "单据性质：",
                fieldValue: "预售单"
            },
            {
                fieldLabel: "预售日期：",
                fieldValue: momentFormat(formVal?.createTime as string),
                className: "li-right"
            },
            ...(shopCode === "SHOP-M-00000017" || shopCode === "SHOP-M-00000018" ? XJHeader : [])

        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser
            },
            {
                fieldLabel: "销售：",
                fieldValue: ""
            },
            {
                fieldLabel: "出纳：",
                fieldValue: ""
            },
            {
                fieldLabel: "仓库：",
                fieldValue: maintenanceList[0]?.warehouseName
            },
            {
                fieldLabel: "会计：",
                fieldValue: ""
            },
            // {
            //     fieldLabel: "客户：",
            //     fieldValue: formVal?.customerName
            // },

        ],
        note: {
            fieldLabel: "备注:",
            fieldValue: formVal?.note
        },
        Total: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.presaleAmount as any),
            receivedAmount: formVal?.presaleAmount
        },
        // Total: [
        //     {
        //         fieldLabel: "大写金额 THE SUM OF：",
        //         fieldValue: numberParseChina(formVal?.receivedAmount as string)
        //     },
        //     {
        //         fieldLabel: "结算类型 Payment Type：",
        //         fieldValue: getTreeNodeName(formVal?.payType as string)
        //     },
        // ],
    }
    const headerTitle = {
        // title: "天津欧豪富港汽车贸易有限公司销售单"
        title: `${formVal.bentityName as string}预售单`

    }
    return (
        <YhOfferPrint
            title="预售单打印详情"
            type=""
            visible={bcmPresalePV}
            onClose={onCancel}
            columns={columns}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printShow={printShow}
            headerTitle={headerTitle}
        />
    )
}
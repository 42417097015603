import React, { useState } from 'react'
import { Modal, Button, Input, message } from 'antd';
import { numberParseChina, formatNum, momentFormat } from "@/utils/utils";
import { fmoney } from "../../utils";
import "./vrmSettlePrintStyle.css";
import moment from 'moment';

//打印
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    colSpan?: number
    render?: (text: string | number) => string
}
export interface IPrintHeader {
    [key: string]: string | number | any
}
export interface IPrintFooter {
    [key: string]: string | number | any
}
interface Item {
    [key: string]: string | number | any
}
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number) => string
}
export interface IPrintType {
    title?: string,
    type?: string,
    visible: boolean,
    onClose: () => void,
    printNum?: () => void,
    onConfirm?: () => void,
    itemClumns?: IPrintClumns[], //维修项目字段及信息
    columns: IPrintClumns[],  //商品字段及信息
    dataSource: Item[],      //商品数据
    itemDataSource?: Item[],  //维修项目数据
    printHeader: IPrintHeader,    //header 及 footer
    printFooter: IPrintFooter,    //header 及 footer
    IHeader: IHeader[],    //header 及 footer
    isBooking?: boolean

}
export interface IHeader {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string,
    border?: boolean,
}

export const SettlePrint = ({
    title = "",
    visible,
    onClose,
    printNum,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    printHeader,
    printFooter,
    IHeader,
    isBooking = false
}: IPrintType) => {

    //返回日期格式为YYYY-MM-DD
    const momentFormatYMD = (text: string) => {
        return text ? moment(text).format("YYYY-MM-DD") : text
    }
    //打印
    const print = () => {
        const el: HTMLElement | null = document.getElementById('vrm-public-print-container');
        const iframe: any = document.createElement('IFRAME');
        let doc: any = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./vrmSettlePrintStyle.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    };
    // 遍历商品td
    const mapGoods = (goods: Item) => {
        return columns.filter(item => item).map((item, index) => <td colSpan={item.colSpan ?? 1} key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods: Item) => {
        return itemClumns?.filter(item => item).map((item, index) => <td colSpan={item.colSpan ?? 1} key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    // const onExport = async () => {
    //     let { BankAccount, address, Bank, phone } = shopObject[shopCode]
    //     let params = {
    //         "account": BankAccount,
    //         "adds": address,
    //         "cheque": Bank,
    //         // "maintenanceCode": formVal.maintenanceCode as string,
    //         "name": title,
    //         "phone": phone,
    //         "type": imgType
    //     }
    //     let result = isBooking ? await maintenanceService.appointmentExcelPrint({ ...params, appointmentCode: formVal.maintenanceCode as string, }) : await maintenanceService.excelPrint({ ...params, "maintenanceCode": formVal.maintenanceCode as string, })
    //     convertRes2Blob(result)
    // }
    // const onExport = () => {

    // }
    const confirmPrint = async () => {
        await printNum?.();
        await print();
    }
    return (
        <Modal
            title="打印详情"
            width={1300}
            visible={visible}
            onCancel={onClose}
            footer={[
                <Button type="link" onClick={onClose} key="back"> 取消</Button>,
                <Button type="link" onClick={print} key="submit">打印预览</Button>,
                <Button type="link" onClick={confirmPrint} key="submit">确认打印</Button>,
                // <Button type="link" onClick={onExport} key="submit">导出excel</Button>,
            ]}
        >
            <div id="vrm-public-print-container">
                <div className="print-container finance">
                    <h2>{title}</h2>
                    <ul className="header-odd">
                        {IHeader && IHeader.map((item, index) => {
                            return <li className={item.className ?? ""} key={index}>{item.fieldLabel}{item.fieldValue}</li>
                        })}
                    </ul>
                    <table className="news-table">
                        <tbody>
                            <tr>
                                <td>车牌</td>
                                <td>{printHeader.licensePlate}</td>
                                <td>客户名称</td>
                                <td style={{ textAlign: "left" }} colSpan={7}>{printHeader.customerName}</td>
                            </tr>
                            <tr>
                                <td>分店</td>
                                <td >{printHeader.shopName}</td>
                                <td>联系人</td>
                                <td >{printHeader.contact}</td>
                                <td>电话</td>
                                <td >{printHeader.phone}</td>
                                <td>销售员</td>
                                <td >{printHeader.createUser}</td>
                                <td>部门经理</td>
                                <td></td>
                            </tr>
                            <tr>
                                <td>车型</td>
                                <td colSpan={3}>{printHeader.vehicleModel}</td>
                                <td>颜色</td>
                                <td></td>
                                <td>送修日期</td>
                                <td>{momentFormatYMD(printHeader.enterTime)}</td>
                                <td>结算日期</td>
                                <td>{momentFormatYMD(printHeader.settleTime)}</td>
                            </tr>
                            <tr>
                                <td>报修项目</td>
                                <td style={{ textAlign: "left" }} colSpan={9}>{printHeader.faultDesc}</td>
                            </tr>
                        </tbody>

                    </table>
                    <table className="item-table">
                        <tbody>
                            <tr className="print-center" >
                                {itemClumns && itemDataSource && itemDataSource.length > 0 && itemClumns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>
                            {itemDataSource && itemDataSource.length > 0 &&
                                itemDataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {itemGoods(item)}
                                    </tr>
                                )}
                        </tbody>
                    </table>
                    <table className="material-table">
                        <tbody>
                            <tr className="print-center" >
                                {columns && dataSource && dataSource.length > 0 && columns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>
                            {dataSource && dataSource.length > 0 &&
                                dataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {mapGoods(item)}
                                    </tr>
                                )
                            }
                        </tbody>
                    </table>

                    <table className="news-table footer-table">
                        <tbody>
                            <tr>
                                <td>工时费</td>
                                <td>{fmoney(printHeader.workingHoursAmount, 2)}</td>
                                <td>材料费</td>
                                <td>{fmoney(printHeader.materialScienceAmount, 2)}</td>
                                <td>合计金额</td>
                                <td>{fmoney(printHeader.totalAmount, 2)}</td>
                                <td>含税合计</td>
                                <td>{fmoney(printHeader.totalAmount, 2)}</td>
                            </tr>
                            <tr>
                                <td>优惠金额</td>
                                <td>{fmoney(printHeader.discountTotalAmount, 2)}</td>
                                <td>实收金额</td>
                                <td>{fmoney(printHeader.receivableTotalAmount, 2)}</td>
                                <td>(大写)</td>
                                <td colSpan={3}>{printHeader.receivableTotalAmountCN}</td>
                            </tr>
                            <tr>
                                <td>待结金额</td>
                                <td >{ }</td>
                                <td>客户签字</td>
                                <td>{ }</td>
                                <td>结算人</td>
                                <td>{printHeader.reckoner}</td>
                                <td>会计</td>
                                <td></td>
                            </tr>
                        </tbody>

                    </table>
                    <ul className="header-odd">
                        <li>制表人：{printHeader.createUser}</li>
                        <li>维保管理系统</li>
                        <li>第1页/共1页</li>
                    </ul>
                    <ul className="header-odd">
                        <li>备注：{printHeader.fmsNote}</li>
                    </ul>
                </div>
            </div>
        </Modal>

    )
}

import React, { useState, useEffect } from 'react'
import { Modal, Button, Input, message, Tooltip } from 'antd';
import { numberParseChina, formatNum } from "@/utils/utils";
import { repairFormatNum } from "../../utils";
import "./scmPrintStyle.css";

export interface IPrintProps {
    id: string;
}

//打印
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number, obj: any) => string
    className?: string
}

interface IPrintFieldInfo {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string
}

interface IPrintShow {
    header: IPrintFieldInfo[],
    footer: IPrintFieldInfo[],
    discounts?: IPrintFieldInfo[],
    totalAmount: any[]
}

interface Item {
    [key: string]: string | number | any
}

interface editTextType {
    title?: string,
    note?: string,
}

export interface IPrintType {
    title?: string,
    modalTitle?: string | React.ReactNode,
    noteEdit?: string,
    type?: string,
    visible: boolean,
    onClose: () => void,
    columns: IPrintClumns[],  //商品字段及信息
    dataSource: Item[],      //商品数据
    printShow: IPrintShow,    //header 及 footer
    paperSize: number,      // 打印的商品条数 (A4纸 40  多联纸 35)
    writePrintNum?: () => void
    footerVisible?: boolean//底部是否显示
}

export function YhPrint({
    title = "",
    type = "",
    paperSize,
    modalTitle = "打印详情",
    noteEdit,
    visible,
    onClose,
    columns,
    dataSource,
    printShow,
    writePrintNum,
    footerVisible = true
}: IPrintType) {

    // const [titles, setTieles] = useState<string>(title);
    // const [notes, setNotes] = useState<string | undefined>(note)

    const [titles, setTieles] = useState<editTextType>({
        title: title,   //标题
        note: undefined, //备注
    });

    useEffect(() => {
        setTieles(origin => ({ ...origin, title }))
    }, [title])
    //设置抬头
    const setTitle = () => {
        let obj: editTextType = {}
        Modal.confirm({
            title: '请输入设置的名称',
            content: <>
                <Input placeholder="请输入设置的名称" onChange={(e) => { obj.title = e.target.value }} />
                {noteEdit && <Input placeholder="请输入设置的备注" onChange={(e) => { obj.note = e.target.value }} />}
            </>,
            okText: '确定',
            cancelText: '取消',
            // onOk: () => {
            //     return new Promise((resolve, reject) => {
            //         if (title) {
            //             resolve(title);
            //         } else {
            //             message.warning("请输入要设置的名称");
            //             reject('请输入要设置的名称');
            //         }
            //     }).then(res => {
            //         setTieles(res as string);
            //     });
            // },
            onOk: () => {
                setTieles({ ...titles, ...obj });
            },
            onCancel: () => { }
        })
    };


    //遍历商品td
    const mapGoods = (goods: Item) => {
        return columns.filter(item => item).map((item, index) => <td className={item.className ?? ''} key={index}>{item.render ? item.render(goods[item.dataIndex], goods) : goods[item.dataIndex] ?? ""}</td>)
    }
    //遍历商品td 1
    const mapGoods1 = (goods: Item) => {
        return columns.filter(item => item).map(item => `<td class=${item.className ?? ''}>${item.render ? item.render(goods[item.dataIndex], goods) : goods[item.dataIndex] ?? ""}</td>`)
    }


    //打印要展示的内容。
    const printContent = (dataSource: Item[], pageNum: number, pageTotal: number, goodsTotalNum: number): HTMLDivElement => {
        //打印的容器
        let PrintContainer = document.createElement("div");
        PrintContainer.setAttribute("class", "print-container");

        let totalAmount = dataSource.reduce((total, next) => { total += (Number(next?.price ?? 0) * Number(next?.num ?? 0)); return total }, 0);  //本页合计

        PrintContainer.innerHTML = `
        ${pageNum > 1 ? '<div style="height: 20px"></div>' : ''}
        <h2 class="title">${titles.title}${type}</h2>
        <ul class="header">
            ${printShow.header && printShow.header.map(item => `<li class=${item.className ?? ""}>${item.fieldLabel + '：' + item.fieldValue}</li>`).join("")}
        </ul>
        <table class="mingxi">
            <tbody>
                <tr class="print-center" >
                    ${columns && columns.filter(item => item).map(item => `<td>${item.title}</td>`).join("")}
                </tr>

                ${dataSource && dataSource.length > 0 && dataSource.map(item => `<tr class="print-center">${mapGoods1(item).join("")}</tr>`).join("")}

                ${printShow.discounts ? `<tr>
                ${printShow.discounts.map((item, index, arr) =>
            `<td colSpan=${index === arr.length - 1 ? columns.length - arr.length - 1 * 3 : 3}>
                               ${item.fieldLabel + "："}
                               <span >${item.fieldValue}</span>
                           </td>`).join("")}
                   </tr>`: ""}

                   ${printShow.totalAmount ?


                printShow.totalAmount.map((item, index) =>
                    `<tr>
                            ${item.map((children: any, indexChild: number) =>
                        `<td class="print-total" colspan=${indexChild % 2 !== 1 ? columns.filter(item => item).length - 3 : 3}>
                                ${children.fieldLabel ? children.fieldLabel + "：" : ""}
                                <span>${children.fieldLabel ? `(${dataSource.length}项)` : ""}</span>
                                <span class="ml-10 f-W600">${children.RMB ? `（大写）${numberParseChina(children.fieldValue ?? 0)}` : ""}</span>
                                <span className="ml-10">${children.fieldLabel ? `${repairFormatNum(children.fieldValue)}` : ""}</span>
                                </td>`
                    ).join("")}
                            </tr>`
                ).join("") : ""}
            </tbody>
        </table>
        <ul class="footer">
            ${printShow.footer && printShow.footer.map(item => `<li class=${item.className ?? ""}>${item.fieldLabel + '：' + item.fieldValue}</li>`).join("")}
            ${(noteEdit && type === "1") ? `<li class='note'>备注: ${titles.note ?? noteEdit}</li>` : `<li class='note'></li>`}
            <li class="pagination">第${pageNum}页/共${pageTotal}页</li>
        </ul>
        `
        return PrintContainer;
    }
    // ${(noteEdit ?? "") && `<li class='note'>备注: ${titles.note ?? noteEdit}</li>`}

    //     <td colspan=${columns.filter(item => item).length - 2}>
    // {(noteEdit && type === "1") && <li className='note'>备注: {titles.note ?? noteEdit}</li>}
    //     本页合计：
    //     <span>${`(${dataSource.length}项)`}</span>
    //     <span class="ml-10 f-W600">${`人民币（大写）${numberParseChina(totalAmount ?? 0)}`}</span>
    //     <td colspan=2>${`${formatNum(totalAmount)} `}</td>

    //     ${printShow.totalAmount ? printShow.totalAmount.map(item => `<tr>
    //     <td colspan=${columns.filter(item => item).length - 2}>
    //         ${item.fieldLabel + "："}
    //         <span>${`(${goodsTotalNum}项)`}</span>
    //         <span class="ml-10 f-W600">${`人民币（大写）${numberParseChina(item.fieldValue ?? 0)}`}</span>
    //     </td>
    //     <td colspan=2>${`${formatNum(item.fieldValue)} `}</td>
    // </tr>`).join("") : ""}
    //打印
    const print = () => {
        // const el: HTMLElement | null = document.getElementById('scm-public-print-container');

        let recordItem: Item[] = [];   //存取每页要打印的商品数据；
        let printPageSize: number = paperSize;   //每张打印纸张要打印多少条;
        let pageNum: number = 1;  //默认从第一页开始
        let goodsLen = dataSource.length; //总共多少条商品
        let pageTotal: number = Math.ceil(goodsLen / printPageSize); //总页数

        let Div = document.createElement("div");

        //商品小于等于纸张的条数， 直接打印。
        if (goodsLen <= printPageSize) {
            Div.appendChild(printContent(dataSource, pageNum, pageTotal, goodsLen));
        }
        //拿到所有数据
        else {
            // 当足够打印一张。 清空之前页的商品数据 页数++
            dataSource.forEach(item => {
                if (recordItem.length === printPageSize) {
                    Div.appendChild(printContent(recordItem, pageNum, pageTotal, goodsLen));
                    recordItem = [];
                    pageNum++;
                };
                recordItem.push(item);
            });
            //当循环不够打印一张， 存取的商品还有数据的话在跑一遍打印
            if (recordItem.length > 0) {
                Div.appendChild(printContent(recordItem, pageNum, pageTotal, goodsLen));
            }
        }


        const iframe: any = document.createElement('IFRAME');
        let doc: any = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./scmPrintStyle.css">`);
        doc.write(Div?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();

            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
    };
    const confirmPrint = async () => {
        await writePrintNum?.()
        await print()
    }

    return (
        <Modal
            title={modalTitle}
            width={1300}
            visible={visible}
            onCancel={onClose}
            footer={footerVisible ? [
                <Button type="link" onClick={onClose} key="back"> 取消</Button>,
                <Tooltip placement="topLeft" title={"点击此按钮时，不会增加打印次数，只做浏览器预览"} arrowPointAtCenter>
                    <Button type="link" onClick={print} key="submit">浏览器打印预览</Button>
                </Tooltip>,
                <Tooltip placement="topLeft" title={"点击此按钮时，会增加打印次数"} arrowPointAtCenter>
                    <Button type="link" onClick={confirmPrint} key="submit">确认打印</Button>
                </Tooltip>,
                <Button type="link" onClick={setTitle} key="setPrintName">设置</Button>
            ] : []}
        >
            <div id="scm-public-print-container">
                {visible && <div className="print-container">
                    <h2 className="title">{`${titles.title}${type}`}</h2>
                    <ul className="header">
                        {printShow.header && printShow.header.map((item, index) => <li key={index} className={item.className ?? ""}>{item.fieldLabel + '：' + item.fieldValue}</li>)}
                    </ul>
                    <table className="mingxi">
                        <tbody>
                            <tr className="print-center" >
                                {columns && columns.filter(item => item).map((item, index) =>
                                    <td key={index}>{item.title}</td>
                                )}
                            </tr>

                            {dataSource && dataSource.length > 0 &&
                                dataSource.map((item, index) =>
                                    <tr key={index} className="print-center">
                                        {mapGoods(item)}
                                    </tr>
                                )}

                            {printShow.discounts && <tr>
                                {printShow.discounts.map((item, index, arr) =>
                                    <td colSpan={index === arr.length - 1 ? columns.length - arr.length - 1 * 3 : 3} key={index}>
                                        {item.fieldLabel + "："}
                                        <span >{item.fieldValue}</span>
                                    </td>
                                )}
                            </tr>}
                            {printShow.totalAmount &&
                                printShow.totalAmount.map((item, index) => {
                                    return (
                                        <tr key={index}>
                                            {item.map((children: any, indexChild: number) =>
                                                <td className="print-total" colSpan={indexChild % 2 !== 1 ? columns.filter(item => item).length - 3 : 3}>
                                                    {children.fieldLabel ? children.fieldLabel + "：" : ""}
                                                    <span>{children.fieldLabel ? `(${dataSource.length}项)` : ""}</span>
                                                    <span className="ml-10">{children.RMB ? `（大写）${numberParseChina(children.fieldValue ?? 0)}` : ""}</span>
                                                    <span className="ml-10">{children.fieldLabel ? `${repairFormatNum(children.fieldValue)}` : ""}</span>
                                                </td>
                                            )}
                                        </tr>
                                    )
                                })}
                        </tbody>
                    </table>
                    <ul className="footer">
                        {printShow.footer && printShow.footer.map((item, index) => <li className={item.className ?? ""} key={index}>{item.fieldLabel + '：' + item.fieldValue}</li>)}
                        {(noteEdit && type === "1") && <li className='note'>备注: {titles.note ?? noteEdit}</li>}
                    </ul>
                </div>}
            </div>
        </Modal>
    )
};

import React, { useState, useEffect } from 'react';
import { IPrintProps, YhPrint } from '../YhPrint';
import { formatNum, momentFormat } from '@/utils/utils';
import moment from 'moment';
import { saleRoService } from './saleRoService';
import { ISCMSaleRoGoodsOne, ISCMSaleRoDetail } from './saleRoTypes';
import { useGlobalModel } from '@/model/globalModel';
import { Radio } from 'antd';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";

export function ScmSaleReturnPrint() {
	const { id, scmSaleReturnPV, setScmSaleReturnPV } = usePrintModel();
	const [formVal, setFormVal] = useState<ISCMSaleRoDetail>();
	const [goodsList, setGoodsList] = useState<ISCMSaleRoGoodsOne[]>([]);
	const { user } = useGlobalModel();

	useEffect(() => {
		scmSaleReturnPV && getSaleRoDetailOne()
	}, [scmSaleReturnPV])

	//获取一条数据
	const getSaleRoDetailOne = async () => {
		let { retData } = await saleRoService.one(id);
		let { details, ...formVal } = retData;
		if (retData) {
			setGoodsList(details.sort((a: any, b: any) => a.materialCode.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a: any, b: any) => a.shelfCode.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => ({ ...item, No: index + 1 })));
			setFormVal(retData);
		}
	};

	const handlePrintNum = async () => {
		await saleRoService.printNum(id);
	}


	const columns = [
		{
			title: '序号',
			dataIndex: 'No',
		},
		{
			title: '商品编码',
			dataIndex: 'materialCode',
		},
		{
			title: '商品名称',
			dataIndex: 'materialName',
		},
		{
			title: '货位',
			dataIndex: 'shelfCode',
		},
		{
			title: '单位',
			dataIndex: 'unitName',
		},
		{
			title: '商品类型',
			dataIndex: 'typeName',
		},
		{
			title: '数量',
			dataIndex: 'num',
			render: (text: string | number) => `-${text}`
		},
		{
			title: '单价',
			dataIndex: 'price',
			render: (text: string | number, record: ISCMSaleRoGoodsOne) => `${formatNum(+text * (1 - +record?.discountRate))}`
		},
		{
			title: '金额',
			dataIndex: 'amount',
			render: (text: string | number) => `-${formatNum(text)}`
		},
		{
			title: '仓库',
			dataIndex: 'warehouseName',
		},
		{
			title: '货位',
			dataIndex: 'shelfCode',
		},
	]

	//头部与底部展示
	const printShow = {
		header: [
			{
				fieldLabel: "购货单位",
				fieldValue: formVal?.customerName ?? "",
				className: "w45"
			},
			{
				fieldLabel: "退货时间",
				fieldValue: momentFormat(formVal?.createTime as string) ?? "",
				className: "w25"

			},
			{
				fieldLabel: "打印时间",
				fieldValue: moment().format("YYYY-MM-DD HH:mm"), //当前时间
				className: "w25"
			},
			{
				fieldLabel: "销售退货单号",
				fieldValue: formVal?.returnCode,
				className: "w40"
			},
			{
				fieldLabel: "销售单号",
				fieldValue: formVal?.saleCode ?? "",
			},


		],
		footer: [
			{
				fieldLabel: "制表人",
				fieldValue: user.userName,
				className: "w16"
			},
			{
				fieldLabel: "业务",
				fieldValue: formVal?.createUser ?? "",
				className: "w16"
			},
			{
				fieldLabel: "财务",
				fieldValue: formVal?.casher ?? "",
				className: "w16"
			},
			{
				fieldLabel: "库管",
				fieldValue: formVal?.storekeeper ?? "",
				className: "w16"
			},
			{
				fieldLabel: "结算员",
				fieldValue: formVal?.reckoner ?? "",
				className: "w16"
			},
			{
				fieldLabel: "客户",
				fieldValue: "",
				className: "w16"
			},
			{
				fieldLabel: "备注",
				fieldValue: formVal?.note ?? "",
				className: "note"
			},
		],
		totalAmount: [
			// {
			// 	fieldLabel: "合计",
			// 	// fieldValue: goodsList.reduce((total, next) => { total += (Number(next?.amount ?? 0)); return total }, 0),
			// 	fieldValue: goodsList.reduce((total, next) => { total += +(next.num ?? 0) * +(next?.price ?? 0); return total }, 0),
			// },
			[
				{
					fieldLabel: "应付金额",
					fieldValue: formVal?.totalAmount,
					RMB: true
				},
				{
					fieldLabel: "优惠金额",
					fieldValue: formVal?.discountTotalAmount,
				},
			],
		]
	}


	const [paperSize, setPaperSize] = useState<number>(40);  //打印的商品条数
	const modalTitle = <>
		<span style={{ marginRight: "15px" }}>打印详情</span>
		<Radio.Group onChange={(e) => setPaperSize(e.target.value)} value={paperSize}>
			<Radio value={45}>A4</Radio>
			<Radio value={40}>10x11</Radio>
		</Radio.Group>
	</>

	return (
		<YhPrint
			modalTitle={modalTitle}
			title={formVal?.bentityName ?? "北京德兴志达商贸有限公司"}
			type="退货单"
			visible={scmSaleReturnPV}
			onClose={() => setScmSaleReturnPV(false)}
			columns={columns}
			dataSource={goodsList}
			printShow={printShow}
			paperSize={paperSize}
			writePrintNum={handlePrintNum}
		/>
	);
}

export default ScmSaleReturnPrint;

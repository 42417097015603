import { httpRequest } from "@/utils/HttpRequest";
import { getBaseCiRequestUrl } from '@/utils/constants'
import { IPurchasePoDetail } from "./purchasePoType"

const baseURL = getBaseCiRequestUrl();

class PurchasePoService {

    async one(id) {
        const apiUrl = `/ci-storage/purchase/findById/${id}`;
        return await httpRequest.get<IPurchasePoDetail>(apiUrl, { timeout: 300000, ...{ baseURL } });
    }

    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/purchase/print/${id}`;
        return await httpRequest.get(apiUrl);
    }

}

export const purchasePoService = new PurchasePoService();
import React, { useState, useEffect } from 'react'
import { numberParseChina } from "@/utils/utils";
import { fmoney, repairFormatNum } from "../../utils";
import { IMaterialDetailList, IItemDetailList, IChargebackDetail } from './chargebackType';
import { SettlePrint, IPrintClumns } from "../settlePrint"
import moment from 'moment';
import { useDictModel } from '@/views/base/dict/dictModel';
import { chargebackService } from './chargebackService';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";
import { MaintenanceReturnStatus, SettleType } from "../../dictMap";

export const VrmMaintenanceReturnPrint = () => {
    const [formVal, setFormVal] = useState<IChargebackDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IMaterialDetailList[]>([]);
    const [itemList, setItemList] = useState<IItemDetailList[]>([]);
    const { getTreeNodeName } = useDictModel();
    const { id, code, vrmMaintenanceReturnPV, setVrmMaintenanceReturnPV } = usePrintModel()

    useEffect(() => {
        vrmMaintenanceReturnPV && getDetailOne()
    }, [vrmMaintenanceReturnPV])
    const getDetailOne = async () => {
        let { retData } = await chargebackService.one(code);
        let { itemDetails, materialDetails, receivableTotalAmount, enterTime, ...from } = retData
        receivableTotalAmount = repairFormatNum(receivableTotalAmount)
        const newItemDetails = itemDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        const newMaterialDetails = materialDetails?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails as IMaterialDetailList[] ?? []);
            setItemList(newItemDetails as IItemDetailList[] ?? []);
            setFormVal({ ...from, receivableTotalAmount, receivableTotalAmountCN: numberParseChina(receivableTotalAmount), enterTime: enterTime })
        }
    }
    const printNum = async () => {
        await chargebackService.printNum(id);
        //await loadData();
    }
    const onClose = () => {
        setVrmMaintenanceReturnPV(false)
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '工时',
            dataIndex: 'workHour',
        },
        {
            title: '单价',
            dataIndex: 'price',
            render: (text: string) => fmoney(text, 2)
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text: string) => text + "%"
        },
        {
            title: '工时费',
            dataIndex: 'receivableAmount',
            render: (text: string) => fmoney(text, 2)
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '材料编号',
            dataIndex: 'materialCode',
        },
        {
            title: '材料名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
            render: (text: string) => fmoney(text, 2)
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text: string) => text + "%"
        },
        {
            title: '金额',
            dataIndex: 'receivableAmount',
            render: (text: string) => fmoney(text, 2)
        },
        {
            title: '备注',
            dataIndex: 'note',
        },
    ]
    let IHeader = [
        {
            fieldLabel: "退单编号:",
            // fieldValue: formVal.returnCode 
            fieldValue: `${formVal.returnCode}    (${MaintenanceReturnStatus.get(formVal?.status as string)})`
        },
        {
            fieldLabel: "维修编号:",
            fieldValue: formVal.maintenanceCode
        },
        {
            fieldLabel: "结算方:",
            fieldValue: formVal.settlementPartyName, //getTreeNodeName(formVal.payType as string)
        }, {
            fieldLabel: "结算类型:",
            fieldValue: SettleType.get(formVal.payType as string)
        }, {
            fieldLabel: "打印日期:",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm")
        },
    ]
    return (
        <SettlePrint
            title={`${formVal.bentityName}修理结算单`}
            visible={vrmMaintenanceReturnPV}
            onClose={onClose}
            printNum={printNum}
            columns={columns as IPrintClumns[]}
            itemClumns={itemClumns as IPrintClumns[]}
            itemDataSource={itemList}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printHeader={formVal}
            printFooter={formVal}
            IHeader={IHeader}
        />

    )
}
import React, { useState, useEffect } from 'react'
// import "./offerPrint.css"
import { YhOfferPrint } from "../OfferPrint/OfferPrint"
import { saleSoService } from './saleSoService';
import { IDetailList, ISaleSoDetail } from './saleSoTypes';
import { numberParseChina, formatNum, momentFormat } from '@/utils/utils';
import { fmoney, mergeRepeatArr } from "../../utils";
import { useDictModel } from '@/views/base/dict/dictModel';
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
import { usePrintModel } from "@/views/receipt/outWarehouse/view/printModel";
import { SaleStatus, SettleType } from "../../dictMap";

export type IMaintenanceAndItemType = {
    total: number
    discount: number
}
export type TotalType = {
    num: number,
    total: number
}

export function VrmSalePrint() {

    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const { CustomerData, user: { shopName, shopId, shopCode } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState<string>("")
    const [formVal, setFormVal] = useState<ISaleSoDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IDetailList[]>([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [itemTotal, setItemTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [total, setTotal] = useState<TotalType>(totalDesc);
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { id, code, vrmSalePV, setVrmSalePV } = usePrintModel()

    // const { shopAndOrg: { shopCode } } = useGlobalModel()

    useEffect(() => {
        vrmSalePV && getPoDetailOne()
    }, [vrmSalePV])
    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await saleSoService.one(code as string);
        let { details, settlementPartyCode, customerName, ...formVal } = retData;

        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }
        let mergeDetails = await mergeRepeatArr(details as IDetailList[]) as IDetailList[];
        let newdetails: any = mergeDetails?.sort((a: any, b: any) => a.materialName.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => {
            newMaintenanceTotal.total += Number(item.num) * Number(item.price)
            newMaintenanceTotal.discount = item.discountRate as number
            newTotal.num += Number(item.num)
            let { price, discountRate, amount } = item

            return {
                ...item,
                No: index + 1,
                notAmount: Number(item.num) * Number(item.price),
                price: fmoney(price, 2),
                taxPrice: fmoney(formatNum(Number(price) * Number(discountRate) / 100), 2),
                amount: fmoney(amount, 2)
            }
        })

        if (settlementPartyCode === "settle-party.internal") {
            setSettlementPartyName(shopName)
        } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
            //let { value: customerCode } = getTreeNodeOne(settlementPartyCode as string)[0]
            // console.log(settlementPartyCode, getTreeNodeOne(settlementPartyCode as string),getFindItem(customerCode, CustomerData))
            //setSettlementPartyName(getFindItem(customerCode, CustomerData).name)
        } else {
            setSettlementPartyName(customerName as string)
        }
        //这里做最后一行汇总信息展示
        // newdetails?.push({
        //     num: newTotal.num,
        //     No: "",
        //     materialCode: "合计",
        //     amount: fmoney(formVal.totalAmount, 2)
        // })
        if (retData) {
            setMaintenanceList(newdetails as ISaleSoDetail[] ?? []);
            setFormVal({ ...formVal, settlementPartyCode, customerName, })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };
    const printNum = async () => {
        await saleSoService.printNum(id);
        //await loadData()
    }
    const onCancel = () => {
        setVrmSalePV(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '折扣',
            dataIndex: 'discountRate',
            render: (text: any) => text + "%"
        },
        // {
        //     // title: '折后单价 Discounted Price',
        //     title: '单价 Unit price',
        //     dataIndex: 'taxPrice',
        // },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
        // ...((code) => {
        //     if (code === "SHOP-M-00000017" || code === "SHOP-M-00000018") {
        //         return []
        //     } else {
        //         return [
        //             {
        //                 title: '分组代码',
        //                 dataIndex: '',
        //             }
        //         ]
        //     }
        // })(shopCode),
    ]
    const XJHeader = [
        {
            fieldLabel: "车牌号：",
            fieldValue: formVal?.licensePlate
        },
        {
            fieldLabel: "底盘号：",
            fieldValue: formVal?.vin
        },

        {
            fieldLabel: "结算方：",
            className: "li-right",
            fieldValue: settlementPartyName,
        },
    ]
    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "销售单号:",
                fieldValue: `${formVal.saleCode}    (${SaleStatus.get(formVal?.status ?? "")})`,
                className: "w35"
            },
            {
                fieldLabel: "客户名称:",
                fieldValue: formVal?.customerName,
                className: "w30"
            },
            {
                fieldLabel: "",
                fieldValue: '',
                className: "li-right"
            },
            {
                fieldLabel: "联系人:",
                fieldValue: formVal?.contact,
                className: "w35"
            },
            {
                fieldLabel: "电话:",
                fieldValue: formVal?.phone,
                className: "w30"
            },
            // {
            //     fieldLabel: "",
            //     fieldValue: "",
            //     className: "w20"
            // },
            {
                fieldLabel: "销售日期:",
                fieldValue: momentFormat(formVal?.createTime as string),
                className: "li-right"
            },
            {
                fieldLabel: "结算方:",
                fieldValue: formVal?.settlementPartyName,
                className: "w35"
            },
            {
                fieldLabel: "结算方式:",
                fieldValue: SettleType.get(formVal?.payType ?? ""),
                className: "w30"
            },
            {
                fieldLabel: "打印日期:",
                fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                className: "li-right"
            },
            ...(shopCode === "SHOP-M-00000017" || shopCode === "SHOP-M-00000018" ? XJHeader : [])

        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser
            },
            {
                fieldLabel: "销售：",
                fieldValue: ""
            },
            {
                fieldLabel: "出纳：",
                fieldValue: ""
            },
            {
                fieldLabel: "仓库：",
                fieldValue: maintenanceList[0]?.warehouseName
            },
            // {
            //     fieldLabel: "会计：",
            //     fieldValue: ""
            // },
            {
                fieldLabel: "客户：",
                // fieldValue: formVal?.customerName
                fieldValue: ''
            },

        ],
        note: shopCode === "SHOP-M-00000017" || shopCode === "SHOP-M-00000018" ? [{
            fieldLabel: "结算备注:",
            fieldValue: formVal?.fmsNote
        }, {
            fieldLabel: "备注:",
            fieldValue: formVal?.note
        }] : [{
            fieldLabel: "备注:",
            fieldValue: formVal?.fmsNote
        }],
        ReceivedTotal: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.receivableAmount as any),
            receivedAmount: formVal?.receivableAmount ? fmoney(formVal?.receivableAmount, 2) : "0.00"
        },
        SaleTotal: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.totalAmount as any),
            receivedAmount: formVal?.totalAmount ? fmoney(formVal?.totalAmount, 2) : "0.00"
        },
        DiscountTotal: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.discountTotalAmount as any),
            receivedAmount: formVal?.discountTotalAmount ? fmoney(formVal?.discountTotalAmount, 2) : "0.00"
        },
        Total: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.receivedAmount as string),
            receivedAmount: formVal?.receivedAmount ? fmoney(formVal?.receivedAmount, 2) : "0.00"
        },

        // Total: [
        //     {
        //         fieldLabel: "大写金额 THE SUM OF：",
        //         fieldValue: numberParseChina(formVal?.receivedAmount as string)
        //     },
        //     {
        //         fieldLabel: "结算类型 Payment Type：",
        //         fieldValue: getTreeNodeName(formVal?.payType as string)
        //     },
        // ],
    }
    const headerTitle = {
        // title: "天津欧豪富港汽车贸易有限公司销售单"
        title: `${formVal.bentityName as string ?? ''}销售单`

    }
    return (
        <YhOfferPrint
            title="销售单打印详情"
            type=""
            visible={vrmSalePV}
            onClose={onCancel}
            columns={columns}
            printNum={printNum}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printShow={printShow}
            headerTitle={headerTitle}
        />
    )
}
export const SaleStatus = new Map([
    ["d-sale-status.paying", "结算中"],
    ["sale.settle", "已结算"],
    ["sale.stock-io", "已出库"],
    ["sale.create", "已开单"],
    ["sale-replenish-status.replenish", "已补单"],
    ["sale.change", "已作废"],
    ["sale.allocation", "调拨销"],
]);

export const SettleType = new Map([
    ["settle-type.other", "其他"],
    ["settle-type.credit", "挂账"],
    ["settle-type.cash", "现结"],
    ["settle-type.balance", "结转"],
    ["settle-type.monthly", "月结"],
]);

export const PurchaseReturnStatus = new Map([
    ["purchase-return.paying", "结算中"],
    ["purchase-return.stock-io", "已出库"],
    ["purchase-return.change", "已作废"],
    ["purchase-return.settle", "已结算"],
    ["purchase-return.create", "已开单"],
]);


export const MaintenanceStatus = new Map([
    ["maintenance.completed", "已完工"],
    ["maintenance-status.paying", "结算中"],
    ["maintenance.settle", "已结算"],
    ["maintenance.approved", "审核通过"],
    ["maintenance.await", "待修"],
    ["maintenance.underway", "在修"],
    ["maintenance.write-off", "已结款"],
    ["maintenance.return-visit", "已回访"],
    ["maintenance.create", "已开单"],
    ["maintenance.under-review", "审核中"],
    ["maintenance.change", "已作废"],
    ["maintenance.stock-io", "已出库"],
    ["maintenance.trans", "已转接"],
]);

export const PurchaseStatus = new Map([
    ["g-purchase-status.settle", "已结款"],
    ["c-purchase-status.partly-io", "部分入库"],
    ["purchase-status.bill", "已结算"],
    ["d-purchase-status.completely-io", "全部入库"],
    ["b-purchase-status.submit", "待入库"],
    ["h-purchase-status.cancel", "已作废"],
    ["a-purchase-status.create", "已开单"],
    ["e-purchase-status.reject", "驳回待结算"],
    ["f-purchase-status.bill", "已结算"],
    ["purchase.completely-io", "已入库"],
    ["purchase-status.paying", "结算中"],
    ["purchase.change", "已作废"],
    ["pur-replenish-status.replenish", "已补单"],
    ["purchase-status.switch", "未转接"],
    ["purchase-status.create", "已开单"],
    ["purchase.allocation", "调拨采"],
]);

export const SaleReturnStatus = new Map([
    ["a-sale-return-status.create", "已开单"],
    ["g-sale-return-status.cancel", "已作废"],
    ["d-sale-return-status.reject", "驳回待结算"],
    ["sale-return-status.write-off", "已结款"],
    ["e-sale-return-status.bill", "已结算"],
    ["b-sale-return-status.submit", "待入库"],
    ["f-sale-return-status.settle", "已结款"],
    ["c-sale-return-status.stock-io", "已入库"],
    ["sale-return-status.create", "已开单"],
    ["sale-return-status.paying", "结算中"],
    ["sale-return-status.settle", "已结算"],
    ["sale-return-status.stock-io", "已入库"],
    ["sale-return-status.change", "已作废"],
    ["sale-return-status.return", "已退货"],
]);


export const MaintenanceReturnStatus = new Map([
    ["maintenance.return.io", "已入库"],
    ["maintenance.return.change", "已作废"],
    ["maintenance.return.settle", "已结算"],
    ["maintenance.chargeback.pend", "退单待审"],
    ["maintenance.chargeback.approve", "退单已审"],
    ["maintenance.return.create", "已开单"],
    ["maintenance.return.paying", "结算中"],
]);

export const ThreeGuaranteesUnit = new Map([
    ["003057", "中集气瓶三包"],
    ["003038", "理工华创三包"],
    ["003018", "吉克三包"],
    ["003023", "亿华通三包"],
    ["003014", "汉德三包"],
    ["003061", "森鹏三包"],
    ["003049", "深海电器三包"],
    ["10601", "潍柴三包"],
    ["003037", "国鸿三包"],
    ["003015", "宏昌天马三包"],
    ["003039", "康明斯三包"],
    ["003043", "江淮三包"],
    ["003041", "海卓三包"],
    ["003056", "英威腾三包"],
    ["003055", "微宏三包"],
    ["003010", "查特三包"],
    ["003004", "法士特三包"],
    ["003053", "华丰三包"],
    ["003024", "延庆重汽重卡三包"],
    ["003030", "特百佳三包"],
    ["003026", "重汽轻卡三包"],
    ["003021", "采埃孚三包"],
    ["10602", "陕汽三包"],
    ["003060", "东风马勒三包"],
    ["003058", "松芝三包"],
    ["003042", "东德三包"],
    ["003062", "氢璞三包"],
    ["003031", "银隆三包"],
    ["003028", "解放三包"],
    ["003048", "华乘易电"],
    ["003047", "吉利三包"],
    ["003059", "现代三包"],
    ["003045", "中财气瓶三包"],
    ["003003", "红岩三包"],
    ["003013", "綦江三包"],
    ["003032", "潍柴新能源三包"],
    ["003022", "盟固利三包"],
    ["003017", "福伊特三包"],
    ["003033", "国轩三包"],
    ["003052", "瑞安天宇三包"],
    ["003020", "大洋电机三包"],
    ["003035", "北京重卡三包"],
    ["003036", "陕汽德创三包"],
    ["003005", "欧曼三包"],
    ["003029", "苏州金龙"],
    ["003016", "上柴三包"],
    ["003019", "方盛三包"],
    ["003011", "福田三包"],
    ["003008", "中通三包"],
    ["003040", "山东奥洋三包"],
    ["003006", "重汽三包"],
    ["003012", "斯堪尼亚三包"],
    ["003025", "大兴重汽重卡三包"],
    ["003034", "德纳三包"],
    ["003007", "欧辉三包"],
    ["003027", "吉克三包"],
    ["003044", "亚星客车三包"],
    ["003051", "欣锐科技三包"],
    ["003009", "威伯科三包"],
    ["003046", "长征三包"],
    ["003050", "捷氢科技三包"],
    ["003054", "锋源三包"],
]);






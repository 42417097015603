import { DataBox } from './DataBox'
import { PageHeader } from './PageHeader'
import { PrintModal } from './PrintModal'

export function EnterWarehouse() {
    return (
        <>
            <PageHeader />
            <DataBox />
            <PrintModal />
        </>
    )
}

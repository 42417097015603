import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/AppTypes";
import { ISCMPurchasePoSearch, ISCMPurchasePoDetail, ISCMPurchasePoInsert, ISCMPurchasePoUpdate, ISCMPurchaseStatistics, ISCMPoCancelDetail, ISCMPoCancelSearch, ISCMWhConfirm, ISCMWhConfirmShopId, ISCMPocancelSettle, ISCMPoSettle, ISCMPurchasePoInsertReplenish, ISCMPoTransferSo, ISCMPoHistoryDetails, ISCMPoHistorySearch, ISCMSoHistorySearch, ISCMSoHistoryDetails, ISCMPOStockGoodsSearch, ISCMPODetailsStockDetail, ISCMPoGoodsDetail, ISCMPOCancelReason } from "./purchasePoTypes";

export interface ISCMPage<T, S> extends IPage<T> {
    statistics: S
}

class PurchasePoService {
    //采购单
    async page(searchVo: ISCMPurchasePoSearch) {
        const apiUrl = "/scm2/purchase/po/listPage1";
        // const apiUrl = "/scm2/purchase/po/listPage";
        return await httpRequest.post<IPage<ISCMPurchasePoDetail>>(apiUrl, searchVo);
    }
    async getStatistics(searchVo: ISCMPurchasePoSearch) {
        const apiUrl = "/scm2/purchase/po/statistics1";
        // const apiUrl = "/scm2/sale/so/statistics";
        return await httpRequest.post<ISCMPurchaseStatistics>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/scm2/purchase/po/findByIdOrCode/${id}`;
        return await httpRequest.get<ISCMPurchasePoDetail>(apiUrl);
    }
    async insert(insertVo: ISCMPurchasePoInsert) {
        const apiUrl = "/scm2/purchase/po/insert";
        return await httpRequest.post<string>(apiUrl, insertVo, { timeout: 300000 });
    }
    //补单
    async insertReplenish(insertVo: ISCMPurchasePoInsertReplenish) {
        const apiUrl = "/scm2/purchase/po/insertReplenish";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }
    async update(updateVo: ISCMPurchasePoUpdate) {
        const apiUrl = "/scm2/purchase/po/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    async export(searchVo: ISCMPurchasePoSearch) {
        const appPageUrl = "/scm2/purchase/po/export1";
        // const appPageUrl = "/scm2/purchase/po/export";
        return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
    }
    // 供应链采购明细导出接口
    async detailsExport(searchVo: ISCMPurchasePoSearch) {
        const appPageUrl = "/scm2/purchase/po/details/export";
        return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
    }
    //采购单明细下载模板
    async downloadTpl() {
        const appPageUrl = "/scm2/purchase/po/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //导出采购单明细
    async exportDetail(id: string, shopId: string) {
        const appPageUrl = `/scm2/purchase/po/exportDetails/${id}/${shopId}`;
        return await httpRequest.getFile(appPageUrl);
    }
    //入库登记导出
    async whExportPodetail(id: string, shopId: string) {
        const appPageUrl = `/scm2/purchase/po/whExportDetails/${id}/${shopId}`;
        return await httpRequest.getFile(appPageUrl);
    }
    //分页取消结算明细
    async poCancel(searchVo: ISCMPoCancelSearch) {
        const apiUrl = "/scm2/purchase/po/cancel";
        return await httpRequest.post<IPage<ISCMPoCancelDetail>>(apiUrl, searchVo);
    }

    //作废采购单
    async disable(params: ISCMPOCancelReason) {
        const apiUrl = `/scm2/purchase/po/disable`;
        return await httpRequest.post<IPage<ISCMPoCancelDetail>>(apiUrl, params);
    }
    //采购单转接库房
    async transferWarehouse(id: string) {
        const apiUrl = `/scm2/purchase/po/transferWarehouse/${id}`;
        return await httpRequest.get<boolean>(apiUrl);
    }

    //采购单库房确认
    async whConfirm(whConfirm: ISCMWhConfirmShopId) {
        const apiUrl = `/scm2/purchase/po/whConfirm`;
        return await httpRequest.post<boolean>(apiUrl, whConfirm);
    }

    //采购单结算
    async settle(settleVo: ISCMPoSettle) {
        const apiUrl = `/scm2/purchase/po/settle`;
        return await httpRequest.post<boolean>(apiUrl, settleVo);
    }

    //采购单取消结算
    async cancelSettle(obj: ISCMPocancelSettle) {
        const apiUrl = `/scm2/purchase/po/cancelSettle`;
        return await httpRequest.post<boolean>(apiUrl, obj);
    }

    //采购单转销售单
    async transfer(obj: ISCMPoTransferSo) {
        const apiUrl = `/scm2/purchase/po/transfer`;
        return await httpRequest.post<string>(apiUrl, obj);
    }

    //采购历史查询
    async poHistory(searchVo: ISCMPoHistorySearch) {
        const apiUrl = "/scm2/purchase/po/purchaseHistory";
        return await httpRequest.post<IPage<ISCMPoHistoryDetails>>(apiUrl, searchVo);
    }
    //销售历史查询
    async soHistory(searchVo: ISCMSoHistorySearch) {
        const apiUrl = "/scm2/sale/so/saleHistory";
        return await httpRequest.post<IPage<ISCMSoHistoryDetails>>(apiUrl, searchVo);
    }
    //采购明细查询接口
    async poDetailsStock(searchVo: ISCMPOStockGoodsSearch) {
        const apiUrl = "/scm2/purchase/po/details/stock";
        return await httpRequest.post<IPage<ISCMPODetailsStockDetail>>(apiUrl, searchVo);
    }
    //查找采购单采购价格
    async purchasePoPrice(id: string) {
        const appPageUrl = `/scm2/purchase/po/detailsId/${id}`;
        return await httpRequest.get<ISCMPoGoodsDetail>(appPageUrl)
    }
}

export const purchasePoService = new PurchasePoService();
import React, { useState, useEffect } from 'react';
import { YhPrintDiscount, IPrintClumns } from "../YhPrint/PrintDiscount";
import { formatNum, momentFormat } from '@/utils/utils';
import { repairFormatNum } from "../../utils";
import moment from 'moment';
import { saleSoService } from './saleSoService';
import { ISCMSaleSoGoodsOne, ISCMSaleSoDetail } from './saleSoTypes';
import { useGlobalModel } from '@/model/globalModel';
import { Checkbox, Radio } from 'antd';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { IPrintProps } from '../YhPrint';
import { useDictModel } from "@/views/base/dict/dictModel";
import { ISelectOptions } from '@/types/AppTypes'
import { usePrintModel } from "@/views/receipt/outWarehouse/view/printModel";

export enum EquilibriumEnum {
    JUST = "+0.01",
    NEGATIVE = "-0.01"
}
/**
 * 结算类型
 */
export enum ISCMSettleTypeEnum {
    SETTLE_CREDIT = "settle-type.credit", //记账
    SETTLE_CASH = "settle-type.cash",  //现结
    SETTLE_BALANCE = "settle-type.balance"//结转
}

export const settleTypeDs: ISelectOptions[] = [
    {
        label: '记账',
        value: ISCMSettleTypeEnum.SETTLE_CREDIT
    },
    {
        label: '现结',
        value: ISCMSettleTypeEnum.SETTLE_CASH
    },
    {
        label: '结转',
        value: ISCMSettleTypeEnum.SETTLE_BALANCE
    }
]
enum IPreferentialType {
    MARKET = "preferential_type.market",//投放
    SERVICE_M = "preferential_type.service-m",//服务配件
    SERVICE_OIL = "preferential_type.service-oil",//服务油品
    DISCOUNT = "preferential_type.discount",//折让
    GIFT = "preferential_type.gift",//赠品
    REBATE = "preferential_type.rebate",//返利
    GENERAL = "preferential_type.general",//普通
    LINK_GIFT = "preferential_type.link-gift",//单赠
    BUY_GIFT = "preferential_type.buy-gift",//买赠
    OTHER_GIFT = "preferential_type.other-gift" //另赠
}

export function ScmSalePrint() {
    const { id, scmSalePV, setScmSalePV } = usePrintModel()
    const [formVal, setFormVal] = useState<ISCMSaleSoDetail>();
    const [goodsList, setGoodsList] = useState<ISCMSaleSoGoodsOne[]>([]);
    const { user } = useGlobalModel();
    const [check, setCheck] = useState<boolean>(false)
    const { getTreeNodeName } = useDictModel(() => []);
    const [approvalUser, setApprovalUser] = useState<string>("")

    useEffect(() => {
        if (scmSalePV) {
            setApprovalUser("");

            getSaleSoDetailOne()
            setPaperSize(38)
        }
    }, [scmSalePV])

    //获取一条数据
    const getSaleSoDetailOne = async () => {
        let { retData } = await saleSoService.one(id);
        let { details, ...formVal } = retData;
        // let newDetails = details.filter(item => !(item.materialCode === EquilibriumEnum.JUST || item.materialCode === EquilibriumEnum.NEGATIVE))
        if (retData) {
            setGoodsList(details.sort((a: any, b: any) => a.materialCode.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a: any, b: any) => a.shelfCode.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => ({ ...item, No: index + 1 })));
            setFormVal(retData)

            //临时授信账户 获取审批人名称
            if (retData?.payRecords?.[0]?.accountType == "account-type.credit") {
                getApprovalUser(retData.shopId, retData.createUser);
            }
        }
    };

    const handlePrintNum = async () => {
        await saleSoService.print(id);
    }

    //获取审批人名称
    const getApprovalUser = async (shopId: string, userName: string) => {
        let { retData } = await saleSoService.getApprovalUser({ shopId: shopId, userName: userName });
        setApprovalUser(retData ?? "");
    };

    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编码',
            dataIndex: 'materialCode',
            render: (text: string, record: ISCMSaleSoGoodsOne) => `${record.giftId ? text + '   赠' : text}`
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
            // render: (text: string , record: ISCMSaleSoGoodsOne) =>  <p className="ellipsis">{record.giftId && <span style={{ background: "orange", color: "white" }}>赠</span>}{text}</p>

        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        // {
        //     title: '数量',
        //     dataIndex: 'num',
        // },
        ...((str) => {
            if (str) {
                return [];
            } else {
                return [{
                    title: '数量',
                    dataIndex: 'num',
                }];
            }
        })(formVal?.preferentialTypeCode === IPreferentialType.LINK_GIFT || formVal?.preferentialTypeCode === IPreferentialType.GIFT),
        ...((str) => {
            if (str) {
                return [{
                    title: "赠品数量",
                    dataIndex: 'giftsNum'
                }];
            } else {
                return [];
            }
        })(formVal?.preferentialTypeCode === IPreferentialType.BUY_GIFT || formVal?.preferentialTypeCode === IPreferentialType.OTHER_GIFT || formVal?.preferentialTypeCode === IPreferentialType.LINK_GIFT),

        {
            title: '单价',
            dataIndex: 'price',
            render: (text: string | number, record: ISCMSaleSoGoodsOne) => `${repairFormatNum(text)}`
        },
        formVal?.preferentialTypeCode === IPreferentialType.GENERAL ? {
            title: '单品优惠',
            dataIndex: 'favorableAmount',
        } : null,
        {
            title: '金额',
            dataIndex: 'amount',
            render: (text: string | number, record: ISCMSaleSoGoodsOne) => `${record.giftId || formVal?.preferentialTypeCode === IPreferentialType.LINK_GIFT ?
                repairFormatNum((Number(record?.price ?? 0) * Number(record.giftsNum))) :
                formVal?.preferentialTypeCode === IPreferentialType.BUY_GIFT ? repairFormatNum((Number(record?.price ?? 0) * (Number(record.giftsNum) + Number(record.num)))) :
                    repairFormatNum((Number(record?.price ?? 0) * Number(record?.num ?? 0) - Number(record?.favorableAmount ?? 0)))}`
        },
        formVal?.preferentialTypeCode === IPreferentialType.GIFT ? {
            title: '赠品',
            dataIndex: 'giftsNum',
        } : null,
        formVal?.preferentialTypeCode === IPreferentialType.DISCOUNT ? {
            title: '下浮',
            dataIndex: 'discountRate',
        } : null,
        formVal?.preferentialTypeCode === IPreferentialType.DISCOUNT ? {
            title: '折后单价',
            dataIndex: 'discountPrice',
            render: (text: string | number, record: ISCMSaleSoGoodsOne) => `${repairFormatNum((Number(record?.price ?? 0) * Number(1 - +record?.discountRate)))}`
        } : null,
        formVal?.preferentialTypeCode === IPreferentialType.DISCOUNT ? {
            title: '折后金额',
            dataIndex: 'amount',
            render: (text: string | number, record: ISCMSaleSoGoodsOne) => `${repairFormatNum(text)}`
        } : null,
        // {
        //     title: '优惠',
        //     dataIndex: 'favorableAmount',
        //     render: (text: string | number, record: ISCMSaleSoGoodsOne) => `${formatNum(text)}`
        // },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
        {
            title: '商品类型',
            dataIndex: 'typeName',
        },
    ]

    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "销售单号",
                fieldValue: formVal?.saleCode,
                className: "w30"
            },
            {
                fieldLabel: "购货单位",
                fieldValue: formVal?.customerName ?? "",
                className: "w45"
            },

            {
                fieldLabel: "对方联系人",
                fieldValue: formVal?.contact ?? "",
                className: "w30"
            },
            {
                fieldLabel: "对方电话",
                fieldValue: formVal?.phone ?? "",
                className: "w30"
            },
            {
                fieldLabel: "单据类型",
                fieldValue: formVal?.preferentialTypeName ?? "",
                className: "w20"
            },
            {
                fieldLabel: "结算类型",
                fieldValue: settleTypeDs.find(item => item.value === formVal?.payType)?.label ?? "",
                className: "w20"
            },
            // {
            // 	fieldLabel: "业务员",
            // 	fieldValue: formVal?.createUser ?? ""
            // },

            // {
            //     fieldLabel: "支付方式",
            //     fieldValue: formVal?.payRecords?.map((item: any) => item?.payMethodName ?? "").join() ?? "",
            //     className: "h34_w30"
            // },
            // {
            //     fieldLabel: "销售类型",
            //     fieldValue: formVal?.saleTypeName ?? "",
            //     className: "w20"
            // },
            // {
            //     fieldLabel: "销售日期",
            //     fieldValue: momentFormat(formVal?.createTime as string) ?? "",
            //     className: "w30"
            // },
            // {
            //     fieldLabel: "打印时间",
            //     fieldValue: moment().format("YYYY-MM-DD HH:mm"), //当前时间
            //     className: "w20"
            // },
            {
                fieldLabel: "打印时间",
                fieldValue: moment().format("YYYY-MM-DD HH:mm"), //当前时间
                className: "h34_w30"
            },
            {
                fieldLabel: "销售日期",
                fieldValue: momentFormat(formVal?.createTime as string) ?? "",
                className: "w30"
            },
            {
                fieldLabel: "销售类型",
                fieldValue: formVal?.saleTypeName ?? "",
                className: "w20"
            },
            {
                fieldLabel: "支付方式",
                fieldValue: getTreeNodeName(formVal?.payRecords?.[0]?.accountType ?? ""),
                className: "w20"
            },
        ],
        footer: [
            {
                fieldLabel: "制表人",
                fieldValue: user.userName,
                className: "w20"
            },
            {
                fieldLabel: "业务",
                fieldValue: formVal?.createUser ?? "",
                className: "w20"
            },
            {
                fieldLabel: "出纳",
                fieldValue: formVal?.casher ?? "",
                className: "w20"
            },
            {
                fieldLabel: "库房",
                fieldValue: formVal?.storekeeper ?? "",
                className: "w20"
            },
            {
                fieldLabel: "客户",
                fieldValue: "",
                className: "w20"
            },
            // {
            // 	fieldLabel: "备注",
            // 	fieldValue: formVal?.note,
            // 	className: "note"
            // },
        ],
        discounts: [
            // ...((str) => {
            //     if (str !== IPreferentialType.DISCOUNT) {
            //         return [
            //             {
            //                 fieldLabel: "整单下浮：",
            //                 fieldValue: formVal?.discountRate,
            //             },
            //             {
            //                 fieldLabel: "整单优惠：",
            //                 fieldValue: formatNum(formVal?.favorableAmount),
            //             },
            //         ]
            //     } else {
            //         return [
            //             {
            //                 fieldLabel: "",
            //                 fieldValue: "",
            //                 colSpan:6
            //             }
            //         ]
            //     }
            // })(formVal?.preferentialTypeCode),
            // {
            //     fieldLabel: "整单下浮：",
            //     fieldValue: formVal?.discountRate,
            // },
            // {
            //     fieldLabel: "整单优惠：",
            //     fieldValue: formatNum(formVal?.favorableAmount),
            // },
            // {
            //     fieldLabel: "",
            //     fieldValue: "",
            //     colSpan: 9
            // },
            // {
            //     fieldLabel: "优惠总额:",
            //     fieldValue: formatNum(formVal?.discountTotalAmount),
            // }
        ],

        totalAmount: [
            // {
            // 	fieldLabel: "合计",
            // 	fieldValue: goodsList.reduce((total, next) => { total += +(next.num ?? 0) * +(next?.price ?? 0); return total }, 0),
            // },
            [
                {
                    fieldLabel: "销售金额",
                    fieldValue: repairFormatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * Number(next?.num ?? 0)); return total }, 0)) //所有合计,
                }, {
                    fieldLabel: "优惠总额",
                    fieldValue: repairFormatNum(formVal?.discountTotalAmount),
                }
            ],
            [{
                fieldLabel: "实收金额",
                fieldValue: formVal?.totalAmount,
                RMB: true
                // fieldValue: formVal?.preferentialTypeCode === IPreferentialType.DISCOUNT ? formatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * Number(next?.num ?? 0)); return total }, 0)):formVal?.totalAmount,

            },
            {
                fieldLabel: null,
                fieldValue: null,
            }
            ]
        ]
    }
    const printShow1 = {
        header: [
            {
                fieldLabel: "销售单号",
                fieldValue: formVal?.saleCode,
                className: "w30"
            },
            {
                fieldLabel: "购货单位",
                fieldValue: formVal?.customerName ?? "",
                className: "w45"
            },
            {
                fieldLabel: "对方联系人",
                fieldValue: formVal?.contact ?? "",
                className: "w30"
            },
            {
                fieldLabel: "对方电话",
                fieldValue: formVal?.phone ?? "",
                className: "w30"
            },
            {
                fieldLabel: "支付方式",
                fieldValue: getTreeNodeName(formVal?.payRecords?.[0]?.accountType ?? ""),
                className: "w30"
            },
            // {
            //     fieldLabel: "结算类型",
            //     fieldValue: settleTypeDs.find(item => item.value === formVal?.payType)?.label ?? "",
            //     className: "w20"
            // },
            // {
            // 	fieldLabel: "业务员",
            // 	fieldValue: formVal?.createUser ?? ""
            // },
            // {
            //     fieldLabel: "单据类型",
            //     fieldValue: formVal?.preferentialTypeName ?? "",
            //     className: "h34_w30"
            // },
            // {
            //     fieldLabel: "销售类型",
            //     fieldValue: formVal?.saleTypeName ?? "",
            //     className: "w20"
            // },
            // {
            //     fieldLabel: "销售日期",
            //     fieldValue: momentFormat(formVal?.createTime as string) ?? "",
            //     className: "w30"
            // },
            // {
            //     fieldLabel: "打印时间",
            //     fieldValue: moment().format("YYYY-MM-DD HH:mm"), //当前时间
            //     className: "w20"
            // },
            {
                fieldLabel: "打印时间",
                fieldValue: moment().format("YYYY-MM-DD HH:mm"), //当前时间
                className: "h34_w30"
            },
            {
                fieldLabel: "销售日期",
                fieldValue: momentFormat(formVal?.createTime as string) ?? "",
                className: "w30"
            },
            {
                fieldLabel: "销售类型",
                fieldValue: formVal?.saleTypeName ?? "",
                className: "w20"
            },
            {
                fieldLabel: "单据类型",
                fieldValue: formVal?.preferentialTypeName ?? "",
                className: "w20"
            },
        ],
        footer: [
            {
                fieldLabel: "制表人",
                fieldValue: user.userName,
                className: "w20"
            },
            {
                fieldLabel: "业务",
                fieldValue: formVal?.createUser ?? "",
                className: "w20"
            },
            {
                fieldLabel: "出纳",
                fieldValue: formVal?.casher ?? "",
                className: "w20"
            },
            {
                fieldLabel: "库房",
                fieldValue: formVal?.storekeeper ?? "",
                className: "w20"
            },
            {
                fieldLabel: "客户",
                fieldValue: "",
                className: "w20"
            },
            {
                fieldLabel: "审核人",
                fieldValue: approvalUser,
                className: approvalUser == "" ? "hidden" : "w30"
            }
            // {
            // 	fieldLabel: "备注",
            // 	fieldValue: formVal?.note,
            // 	className: "note"
            // },
        ],
        totalAmount: [

            [{
                fieldLabel: "销售金额",
                fieldValue: formVal?.preferentialTypeCode === IPreferentialType.GIFT ? repairFormatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * (+(next?.num ?? 0) + +(next?.giftsNum ?? 0))); return total }, 0)) :
                    formVal?.preferentialTypeCode === IPreferentialType.OTHER_GIFT || formVal?.preferentialTypeCode === IPreferentialType.BUY_GIFT ? repairFormatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * (+(next?.num ?? 0) + +(next?.giftsNum ?? 0))); return total }, 0)) :
                        formVal?.preferentialTypeCode === IPreferentialType.LINK_GIFT ? repairFormatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * (+(next?.giftsNum ?? 0))); return total }, 0)) :
                            repairFormatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * Number(next?.num ?? 0)); return total }, 0)),
            },
            ...((str) => {
                if (str) {
                    return [{
                        fieldLabel: "优惠金额",
                        fieldValue: formVal?.preferentialTypeCode === IPreferentialType.BUY_GIFT ? repairFormatNum(formVal?.details?.reduce((total, next) => { total += (Number(next?.price ?? 0) * (+(next?.giftsNum ?? 0))); return total }, 0)) : formVal?.discountTotalAmount,
                    }];
                } else {
                    return [];
                }
            })(formVal?.preferentialTypeCode === IPreferentialType.BUY_GIFT || formVal?.preferentialTypeCode === IPreferentialType.OTHER_GIFT || formVal?.preferentialTypeCode === IPreferentialType.GENERAL || formVal?.preferentialTypeCode === IPreferentialType.LINK_GIFT),
            ], [
                {
                    fieldLabel: "实收金额",
                    fieldValue: formVal?.totalAmount,
                    RMB: true
                },
                {
                    fieldLabel: null,
                    fieldValue: null,
                }
            ]
        ]
    }
    const checkChange = (e: CheckboxChangeEvent) => setCheck(e.target.checked)

    const [paperSize, setPaperSize] = useState<number>(38);  //打印的商品条数  (没有单价的多展示两条商品)
    const modalTitle = <>
        <span style={{ marginRight: "15px" }}>打印详情</span>
        <Radio.Group onChange={(e) => setPaperSize(e.target.value)} value={paperSize}>
            <Radio value={41}>A4</Radio>
            <Radio value={38}>10x11</Radio>
        </Radio.Group>
        <Checkbox checked={check} onChange={checkChange}>是否显示差额</Checkbox>

    </>
    let newGoodsList = check ? goodsList : goodsList.filter(item => !(item.materialCode === EquilibriumEnum.JUST || item.materialCode === EquilibriumEnum.NEGATIVE))
    // newGoodsList = newGoodsList.sort((a, b) => {
    //     let val = a.materialName.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' })
    //     return val === 0 ? a.materialCode.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' }) : val
    // }).map((item, index) => ({ ...item, No: index + 1 }))
    return (
        <YhPrintDiscount
            modalTitle={modalTitle}
            title={formVal?.bentityName}
            visible={scmSalePV}
            onClose={() => setScmSalePV(false)}
            columns={columns as IPrintClumns[]}
            dataSource={newGoodsList}
            printShow={formVal?.preferentialTypeCode === IPreferentialType.DISCOUNT ? printShow : printShow1}
            noteEdit={formVal?.note ?? " "}
            paperSize={paperSize}
            writePrintNum={handlePrintNum}
        />
    );
}

export default ScmSalePrint;

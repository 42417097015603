import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/AppTypes";
import { ISCMPurchaseRoSearch, ISCMPurchaseRoDetail, ISCMPurchaseRoInsert, ISCMPurchaseRoUpdate, ISCMPurchaseRoStatistics, ISCMPRoGoodsOne, ISCMPRoSearch, ISCMROCancelReason } from "./purchaseRoTypes";

export interface ISCMPage<T, S> extends IPage<T> {
    statistics: S
}


class PurchaseRoService {
    //采购退货单
    async page(searchVo: ISCMPurchaseRoSearch) {
        const apiUrl = "/scm2/purchase/ro/listPage1";
        // const apiUrl = "/scm2/purchase/ro/listPage";
        return await httpRequest.post<IPage<ISCMPurchaseRoDetail>>(apiUrl, searchVo);
    }
    async getStatistics(searchVo: ISCMPurchaseRoSearch) {
        const apiUrl = "/scm2/purchase/ro/statistics1";
        // const apiUrl = "/scm2/sale/so/statistics";
        return await httpRequest.post<ISCMPurchaseRoStatistics>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/scm2/purchase/ro/findByIdOrCode/${id}`;
        return await httpRequest.get<ISCMPurchaseRoDetail>(apiUrl);
    }
    async insert(insertVo: ISCMPurchaseRoInsert) {
        const apiUrl = "/scm2/purchase/ro/insert";
        return await httpRequest.post<string>(apiUrl, insertVo, { timeout: 300000 });
    }
    async update(updateVo: ISCMPurchaseRoUpdate) {
        const apiUrl = "/scm2/purchase/ro/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo, { timeout: 300000 });
    }

    // 列表导出
    async export(searchVo: ISCMPurchaseRoSearch) {
        const apiUrl = "/scm2/purchase/ro/export1";
        // const apiUrl = "/scm2/purchase/ro/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }

    // 详情导出
    async detaiEexport(searchVo: ISCMPurchaseRoSearch) {
        const apiUrl = "/scm2/purchase/ro/details/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo, { timeout: 300000 });
    }

    //待选则采购明细商品分页查询
    async poPageDetail(searchVo: ISCMPRoSearch) {
        const apiUrl = "/scm2/purchase/po/detail/findDetailPage";
        return await httpRequest.post<IPage<ISCMPRoGoodsOne>>(apiUrl, searchVo);
    }
    //不限类型接口
    async poPageUnlimited(searchVo: ISCMPRoSearch) {
        const apiUrl = "/scm2/purchase/po/detail/unlimited/findDetailPage";
        return await httpRequest.post<IPage<ISCMPRoGoodsOne>>(apiUrl, searchVo);
    }

    //采购退货转库房
    async transferWarehouse(id: string) {
        const apiUrl = `/scm2/purchase/ro/transferWarehouse/${id}`;
        return await httpRequest.get<boolean>(apiUrl);
    }

    //作废采购退货单
    async disable(params: ISCMROCancelReason) {
        const apiUrl = `/scm2/purchase/ro/disable`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }


    //采购退货单明细下载模板
    async downloadTpl() {
        const appPageUrl = "/scm2/purchase/ro/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    //导出采购退货单明细
    async exportDetail(id: string, shopoId: string) {
        const appPageUrl = `/scm2/purchase/ro/exportDetails/${id}/${shopoId}`;
        return await httpRequest.getFile(appPageUrl);
    }

    // 采购退货明细查询(非受限 与 受限整合为一个接口)
    async detailsPage(searchVo: ISCMPRoSearch) {
        const apiUrl = "/scm2/inventory/details/page";
        return await httpRequest.post<IPage<ISCMPRoGoodsOne>>(apiUrl, searchVo);
    }

}

export const purchaseRoService = new PurchaseRoService();
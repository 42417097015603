import { httpRequest } from "@/utils/HttpRequest";
import { getBaseCiRequestUrl } from '@/utils/constants'
import {
    ISaleSoDetail,
} from "./saleSoTypes"

const baseURL = getBaseCiRequestUrl();

class SaleSoService {

    async one(id) {
        const apiUrl = `/ci-storage/sale/findById/${id}`;
        return await httpRequest.get<ISaleSoDetail>(apiUrl, { timeout: 300000, ...{ baseURL } });
    }

    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/sale/print/${id}`;
        return await httpRequest.get(apiUrl, { timeout: 300000, ...{ baseURL } });
    }
}

export const saleSoService = new SaleSoService();
import { httpRequest } from "@/utils/HttpRequest";
import { getBaseCiRequestUrl } from '@/utils/constants'
import {
    ISaleRoDetail,
} from "./saleRoTypes"

const baseURL = getBaseCiRequestUrl();

class SaleRoService {

    async one(id) {
        const apiUrl = `/ci-storage/sreturn/findById/${id}`;
        return await httpRequest.get<ISaleRoDetail>(apiUrl, { timeout: 300000, ...{ baseURL } });
    }
    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/sreturn/print/${id}`;
        return await httpRequest.get(apiUrl, { timeout: 300000, ...{ baseURL } });
    }
}

export const saleRoService = new SaleRoService();
import React, { useState, useEffect } from 'react';
import { IPrintProps, YhPrint } from '../YhPrint';
import { formatNum, momentFormat } from '@/utils/utils';
import { purchaseRoService } from './purchaseRoService';
import { ISCMPRoGoodsDetail, ISCMPurchaseRoDetail, ISCMPRoGoodsOne } from './purchaseRoTypes';
import moment from 'moment';
import { useGlobalModel } from '@/model/globalModel';
import { Radio } from 'antd';
import { usePrintModel } from "@/views/receipt/outWarehouse/view/printModel";

export function ScmPurcharseReturnPrint() {
	const { id, scmPurcharseReturnPV, setScmPurcharseReturnPV } = usePrintModel()
	const [formVal, setFormVal] = useState<ISCMPurchaseRoDetail>();
	const [goodsList, setGoodsList] = useState<ISCMPRoGoodsOne[]>([]);
	const { user } = useGlobalModel();
	useEffect(() => {
		scmPurcharseReturnPV && getPRoDetailOne()
	}, [scmPurcharseReturnPV])

	//获取一条数据
	const getPRoDetailOne = async () => {
		let { retData } = await purchaseRoService.one(id);
		let { details, ...formVal } = retData;
		if (retData) {
			setGoodsList(details.sort((a: any, b: any) => a.materialCode.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a: any, b: any) => a.shelfCode.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => ({ ...item, No: index + 1 })));
			setFormVal(retData);
		}
	};

	const columns = [
		{
			title: '序号',
			dataIndex: 'No',
		},
		{
			title: '商品编号',
			dataIndex: 'materialCode',
		},
		{
			title: '商品名称',
			dataIndex: 'materialName',
		},
		{
			title: '替换编号',
			dataIndex: 'replaceCode',
		},
		{
			title: '单位',
			dataIndex: 'unitName',
		},
		{
			title: '数量',
			dataIndex: 'num',
			render: (text: string | number, record: ISCMPurchaseRoDetail) => `-${text}`
		},
		{
			title: '商品类型',
			dataIndex: 'typeName',
		},
		{
			title: '单价',
			dataIndex: 'price',
			render: (text: string | number, record: ISCMPurchaseRoDetail) => `${formatNum(+text * (1 - +record.discountRate))}`
		},
		{
			title: '金额',
			dataIndex: 'amount',
			render: (text: string | number) => `${formatNum(text)}`
		},
		{
			title: '仓库',
			dataIndex: 'warehouseName',
		},
		{
			title: '货位',
			dataIndex: 'shelfCode',
		},
	]

	//头部与底部展示
	const printShow = {
		header: [

			// {
			// 	fieldLabel: "支付方式",
			// 	fieldValue: formVal?.payRecords?.map((item: any) => item?.payMethodName ?? "").join() ?? ""
			// },
			{
				fieldLabel: "采购单号",
				fieldValue: formVal?.purchaseCode
			},
			{
				fieldLabel: "退货日期",
				fieldValue: momentFormat(formVal?.createTime as string) ?? "",
				className: "w30"
			},
			{
				fieldLabel: "打印时间",
				fieldValue: moment().format("YYYY-MM-DD HH:mm"),//当前时间
				className: "w30"
			},
			{
				fieldLabel: "采购退货单号",
				fieldValue: formVal?.returnCode,
				className: "h34_w40"
			},
			{
				fieldLabel: "退货单位",
				fieldValue: formVal?.supplierName ?? "",
				className: "h34_w40"
			},
		],
		footer: [
			{
				fieldLabel: "制表人",
				fieldValue: user.userName,
				className: "w20"
			},
			{
				fieldLabel: "业务",
				fieldValue: formVal?.createUser ?? "",
				className: "w20"
			},
			{
				fieldLabel: "财务",
				fieldValue: formVal?.casher ?? "",
				className: "w20"
			},
			{
				fieldLabel: "库管",
				fieldValue: formVal?.storekeeper ?? "",
				className: "w20"
			},
			{
				fieldLabel: "结算员",
				fieldValue: formVal?.reckoner ?? "",
				className: "w20"
			},
			{
				fieldLabel: "退货原因",
				fieldValue: formVal?.note ?? "",
				className: "note"
			},
		],
		totalAmount: [
			// {
			// 	fieldLabel: "合计",
			// 	fieldValue: goodsList.reduce((total, next) => { total += (Number(next?.amount ?? 0)); return total }, 0),
			// },
			[
				{
					fieldLabel: "应收金额",
					fieldValue: formVal?.stockIoTotalAmount,
					RMB: true
				},
				{
					fieldLabel: "优惠金额",
					fieldValue: formVal?.discountTotalAmount,
				}
			],
		]
	}

	const [paperSize, setPaperSize] = useState<number>(40);  //打印的商品条数
	const modalTitle = <>
		<span style={{ marginRight: "15px" }}>打印详情</span>
		<Radio.Group onChange={(e) => setPaperSize(e.target.value)} value={paperSize}>
			<Radio value={45}>A4</Radio>
			<Radio value={40}>10x11</Radio>
		</Radio.Group>
	</>

	return (
		<YhPrint
			modalTitle={modalTitle}
			title={formVal?.bentityName ?? "北京德兴志达商贸有限公司"}
			type="退货单"
			visible={scmPurcharseReturnPV}
			onClose={() => setScmPurcharseReturnPV(false)}
			columns={columns}
			dataSource={goodsList}
			printShow={printShow}
			paperSize={paperSize}
		/>
	);
}

export default ScmPurcharseReturnPrint;

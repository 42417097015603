import React, { useState, useEffect } from 'react'
// import "./offerPrint.css"
import { YhOfferPrint } from "../OfferPrint/OfferPrint"
import { purchasePoService } from './purchasePoService';
import { IDetailList, IPurchasePoDetail } from './purchasePoType';
import { numberParseChina, formatNum, momentFormat } from '@/utils/utils';
import { fmoney } from "../../utils";
import { useDictModel } from '@/views/base/dict/dictModel';
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";
import { PurchaseStatus } from "../../dictMap";

export type IMaintenanceAndItemType = {
    total: number
    discount: number
}
export type TotalType = {
    num: number,
    total: number
}
export function VrmPurcharsePrint() {
    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const { CustomerData, user: { shopName, shopId, shopCode } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState<string>("")
    const [formVal, setFormVal] = useState<IPurchasePoDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IDetailList[]>([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [itemTotal, setItemTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [total, setTotal] = useState<TotalType>(totalDesc);
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { id, code, vrmPurcharsePV, setVrmPurcharsePV } = usePrintModel()

    // const { shopAndOrg: { shopCode } } = useGlobalModel()

    useEffect(() => {
        vrmPurcharsePV && getPoDetailOne()
    }, [vrmPurcharsePV])
    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await purchasePoService.one(code);
        let { details, settlementPartyCode, customerName, ...formVal } = retData;

        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }

        let newdetails: any = details?.sort((a: any, b: any) => a.materialName.localeCompare(b.materialName, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => {
            newMaintenanceTotal.total += Number(item.num) * Number(item.price)
            newMaintenanceTotal.discount = item.discountRate as number
            newTotal.num += Number(item.num)
            let { price, discountRate, amount } = item

            return {
                ...item,
                No: index + 1,
                notAmount: Number(item.num) * Number(item.price),
                price: fmoney(price, 2),
                taxPrice: fmoney(formatNum(Number(price) * Number(discountRate) / 100), 2),
                amount: fmoney(amount, 2)
            }
        })

        if (settlementPartyCode === "settle-party.internal") {
            setSettlementPartyName(shopName)
        } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
            //let { value: customerCode } = getTreeNodeOne(settlementPartyCode as string)[0]
            // console.log(settlementPartyCode, getTreeNodeOne(settlementPartyCode as string),getFindItem(customerCode, CustomerData))
            //setSettlementPartyName(getFindItem(customerCode, CustomerData).name)
        } else {
            setSettlementPartyName(customerName as string)
        }
        //这里做最后一行汇总信息展示
        // newdetails?.push({
        //     num: newTotal.num,
        //     No: "",
        //     materialCode: "合计",
        //     amount: fmoney(formVal.totalAmount, 2)
        // })
        if (retData) {
            setMaintenanceList(newdetails as IPurchasePoDetail[] ?? []);
            setFormVal({ ...formVal, settlementPartyCode, customerName, })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };
    const printNum = async () => {
        await purchasePoService.printNum(id);
        //await loadData()
    }
    const onCancel = () => {
        setVrmPurcharsePV(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
    ]

    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "采购单号：",
                fieldValue: `${formVal.purchaseCode}    (${PurchaseStatus.get(formVal?.status as string)})`,
                className: "w30"
            },
            {
                fieldLabel: "制单日期：",
                fieldValue: formVal?.createTime,
            },
            {
                fieldLabel: "",
                fieldValue: "",
                className: "w20"
            },
            {
                fieldLabel: "打印日期：",
                fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                className: "li-right"
            },
            {
                fieldLabel: "供应商名称",
                fieldValue: formVal?.supplierName
            },

        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser
            },
            {
                fieldLabel: "销售：",
                fieldValue: ""
            },
            {
                fieldLabel: "出纳：",
                fieldValue: ""
            },
            {
                fieldLabel: "仓库：",
                fieldValue: maintenanceList[0]?.warehouseName
            },
            {
                fieldLabel: "会计：",
                fieldValue: ""
            },
            // {
            //     fieldLabel: "客户：",
            //     fieldValue: formVal?.customerName
            // },

        ],
        note: (shopCode == 'SHOP-M-00000017' || shopCode == 'SHOP-M-00000018') ? [
            {
                fieldLabel: "结算备注:",
                fieldValue: formVal?.fmsNote
            }, {
                fieldLabel: "备注:",
                fieldValue: formVal?.note
            }
        ] : [{
            fieldLabel: "备注:",
            fieldValue: formVal?.fmsNote
        }],
        Total: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.payableAmount as number),
            receivedAmount: fmoney(formVal?.payableAmount, 2)
        },

        // Total: [
        //     {
        //         fieldLabel: "大写金额 THE SUM OF：",
        //         fieldValue: numberParseChina(formVal?.receivedAmount as string)
        //     },
        //     {
        //         fieldLabel: "结算类型 Payment Type：",
        //         fieldValue: getTreeNodeName(formVal?.payType as string)
        //     },
        // ],
    }
    const headerTitle = {
        // title: "天津欧豪富港汽车贸易有限公司销售单"
        title: `${formVal.bentityName as string ?? ''}进货单`

    }
    return (
        <YhOfferPrint
            title="进货单打印详情"
            type=""
            visible={vrmPurcharsePV}
            onClose={onCancel}
            columns={columns}
            printNum={printNum}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printShow={printShow}
            headerTitle={headerTitle}
        />
    )
}
import { httpRequest } from "@/utils/HttpRequest";
import { getBaseCiRequestUrl } from '@/utils/constants'
import { PrintInfoFields, IMaintenanceField } from './maintenanceType'

const baseURL = getBaseCiRequestUrl();

class MaintenanceService {

    async getOrderOne(id) {
        const apiUrl = `/ci/order/platform_one/${id}`;
        return await httpRequest.post<IMaintenanceField>(apiUrl, {}, { timeout: 300000, ...{ baseURL } });
    }

    async printNum(data) {
        const apiUrl = `/ci/order/print-count`;
        return await httpRequest.post(apiUrl, data, { timeout: 300000, ...{ baseURL } });
    }

    async orderPrintInfo(data) {
        const apiUrl = `/ci/order/print-info`;
        return await httpRequest.post<PrintInfoFields>(apiUrl, data, { timeout: 300000, ...{ baseURL } });
    }

}

export const maintenanceService = new MaintenanceService();
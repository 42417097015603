import React, { useState } from 'react'
import { Modal, Button, Input, message } from 'antd';
import "./bcmOfferPrintStyle.css"
// import { useMaintenanceModel } from "../maintenanceModel"
//报价单打印
export interface IHeaderTitle {
    title: string,
}
export interface IPrintClumns {
    title: string,
    dataIndex: string,
    render?: (text: string | number) => string
}
export interface ITitlePrint {
    quotation: string,
    quotationEnL: string
}
export interface IHeaderPrint {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string,
    border?: boolean,
}
export interface IFooterPrint {
    fieldLabel: string,
    fieldValue?: string | number,
    className?: string,
    border?: boolean,
}
interface Item {
    [key: string]: string | number | any
}
interface IPrintShow {
    header: IHeaderPrint[],
    footer?: IFooterPrint[],
    footerPeople?: IFooterPrint[],
    note?: IFooterPrint,
    Total?: any,
    [ket: string]: any
    // totalAmount: IPrintFieldInfo[],
    // itemAmount?: IPrintFieldInfo[]
}
export interface IPrintType {
    title?: string,
    type?: string,
    visible: boolean,
    onClose: () => void,
    onConfirm?: () => void,
    itemClumns?: IPrintClumns[], //维修项目字段及信息
    columns: IPrintClumns[],  //商品字段及信息
    dataSource: Item[],      //商品数据
    itemDataSource?: Item[],  //维修项目数据
    printShow: IPrintShow    //header 及 footer
    headerTitle: IHeaderTitle, //头部标题
}
export function YhOfferPrint({
    title = "",
    type = "",
    visible,
    onClose,
    onConfirm = () => null,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    printShow,
    headerTitle
}: IPrintType) {
    // const {
    //     offerPrint: visible,
    //     setOfferPrint,
    // } = useMaintenanceModel();
    // const [offerPrint, setOfferPrint] = useState<boolean>(false)
    //遍历商品td
    const mapGoods = (goods: Item) => {
        return columns.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods: Item) => {
        return itemClumns?.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex]) : goods[item.dataIndex]}</td>)
    }
    const print = () => {
        const el: HTMLElement | null = document.getElementById('bcm-offerPrint-print-container');
        const iframe: any = document.createElement('IFRAME');
        let doc: any = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./bcmOfferPrintStyle.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
        onConfirm()
    }
    return <Modal
        title={title}
        width={1200}
        visible={visible}
        onCancel={onClose}
        footer={[
            <Button type="link" onClick={onClose} key="back"> 取消</Button>,
            <Button type="link" onClick={print} key="submit">确认打印</Button>,
            // <Button type="link" onClick={setTitle} key="setPrintName">设置</Button>
        ]}
    >
        {visible && <div id="bcm-offerPrint-print-container">
            <div className="offerPrint-container">
                <div className="title">
                    <h2>{headerTitle.title}</h2>
                    {/* <h2>Asia-Africa International FZE</h2>
                    <h2>报价单</h2> */}
                </div>
                <ul className="offerPrint-header">
                    {printShow.header && printShow.header.map((item, index) => {
                        return <li className={item.className ?? ""} key={index}>{item.fieldLabel}{item.fieldValue}</li>
                    })}
                </ul>
                <table className="mingxi">
                    <tbody>
                        <tr className="print-center" >
                            {columns && columns.filter(item => item).map((item, index) =>
                                <td key={index}>{item.title}</td>
                            )}
                        </tr>
                        {dataSource && dataSource.length > 0 &&
                            dataSource.map((item, index) =>
                                <tr key={index} className="print-center">
                                    {mapGoods(item)}
                                </tr>
                            )}
                        {itemDataSource && itemDataSource.length > 0 &&
                            itemDataSource.map((item, index) =>
                                <tr key={index} className="print-center">
                                    {itemGoods(item)}
                                </tr>
                            )}
                        <tr className="print-left">
                            <td colSpan={2} className="border-right-none">{printShow.SaleTotal ? `销售金额：${printShow.SaleTotal['numAll']}项` : ""}</td>
                            <td colSpan={2}>{printShow.SaleTotal ? printShow.SaleTotal['receivedAmount'] : ""}</td>
                            <td colSpan={3} className="border-right-none">{printShow.Total ? `${printShow.Total['label'] ?? '应收金额'}：${printShow.Total['numAll']}项` : ""}</td>
                            <td colSpan={columns.length - 7}>{printShow.Total ? printShow.Total['receivedAmount'] : ""}</td>
                        </tr>
                        {printShow.DiscountTotal && <tr className="print-left">
                            <td colSpan={2} className="border-right-none">{printShow.DiscountTotal ? `优惠金额：${printShow.DiscountTotal['numAll']}项` : ""}</td>
                            <td colSpan={2}>{printShow.Total ? printShow.DiscountTotal['receivedAmount'] : ""}</td>
                            <td colSpan={3} className="border-right-none">{printShow.ReceivedTotal ? `已收金额：${printShow.ReceivedTotal['numAll']}项` : ""}</td>
                            <td colSpan={columns.length - 7}>{printShow.ReceivedTotal ? printShow.ReceivedTotal['receivedAmount'] : ""}</td>
                        </tr>}
                        {/* {printShow.DiscountTotal && <tr className="print-left">
                            <td colSpan={2} className="border-right-none">{printShow.DiscountTotal?`销售金额：`:""}</td>
                            <td colSpan={2}>{printShow.Total?printShow.DiscountTotal['totalAmount']:""}</td>
                            <td colSpan={2} className="border-right-none">{printShow.DiscountTotal?`优惠金额：`:""}</td>
                            <td colSpan={2}>{printShow.Total?printShow.DiscountTotal['receivedAmount']:""}</td>
                        </tr>} */}
                        {/* <tr className="print-left">
                            <td colSpan={1} className="border-right-none">本页合计：</td>
                            <td colSpan={1} className="border-right-none">{printShow.Total['numAll']}项</td>
                            <td colSpan={4} className="border-right-none">人民币（大写）：{printShow.Total['receivedAmountChina']}</td>
                            <td colSpan={3}>{printShow.Total['receivedAmount']}</td>
                        </tr> */}
                    </tbody>
                </table>
                <div className="footer">
                    <ul className="footer-people">
                        {printShow.footerPeople?.map((item, index) => {
                            return <li key={index}>{item.fieldLabel}{item.fieldValue}</li>
                        })}
                        {/* <li>开单：</li>
                        <li>业务员：</li>
                        <li>财务：</li>
                        <li>仓库：</li>
                        <li>收货：</li> */}
                    </ul>

                    {printShow.note && <p className="note-footer">{printShow?.note?.fieldLabel}{printShow?.note?.fieldValue}</p>}
                </div>
            </div>
        </div>}
    </Modal>
}
import { usePrintModel } from "./printModel";
import useGlobalModel from '@/model/globalModel'

import { ScmSaleReturnPrint } from "../../print/scm/scmSaleReturn/ScmSaleReturnPrint";
import { ScmPurcharsePrint } from "../../print/scm/scmPurcharse/ScmPurcharsePrint";
import { ScmUnrelateReturnPrint } from "../../print/scm/scmUnrelateReturn/ScmUnrelateReturnPrint";

import { BcmSaleReturnPrint } from "../../print/bcm/bcmSaleReturn/BcmSaleReturnPrint";
import { BcmPurcharsePrint } from "../../print/bcm/bcmPurcharse/BcmPurcharsePrint";
import { BcmPresaleReturnPrint } from "../../print/bcm/bcmPresaleReturn/BcmPresaleReturnPrint";

import { VrmSaleReturnPrint } from "../../print/vrm/vrmSaleReturn/VrmSaleReturnPrint";
import { VrmPurcharsePrint } from "../../print/vrm/vrmPurcharse/VrmPurcharsePrint";
import { VrmMaintenanceReturnPrint } from "../../print/vrm/vrmMaintenanceReturn/VrmMaintenanceReturnPrint";

import { CiSaleReturnPrint } from "../../print/ci/ciSaleReturn/CiSaleReturnPrint";
import { CiPurcharsePrint } from "../../print/ci/ciPurcharse/CiPurcharsePrint";
import { CiMaintenanceReturnPrint } from "../../print/ci/ciMaintenanceReturn/CiMaintenanceReturnPrint";

export const PrintModal = () => {

    const { user: { branch } } = useGlobalModel()
    const { } = usePrintModel()

    return (
        <>
            <ScmSaleReturnPrint />
            <ScmPurcharsePrint />
            <ScmUnrelateReturnPrint />

            <BcmSaleReturnPrint />
            <BcmPurcharsePrint />
            <BcmPresaleReturnPrint />

            <VrmSaleReturnPrint />
            <VrmPurcharsePrint />
            <VrmMaintenanceReturnPrint />

            <CiSaleReturnPrint />
            <CiPurcharsePrint />
            <CiMaintenanceReturnPrint />
        </>
    )
}

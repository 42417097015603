import React, { useState } from 'react'
import { Modal, Button, Input, message } from 'antd';
import "./ciOfferPrintStyle.css"
export function YhOfferPrint({
    title = "",
    type = "",
    visible,
    onClose,
    printNum,
    onConfirm = () => null,
    columns,
    itemClumns,
    itemDataSource,
    dataSource,
    printShow,
    headerTitle,
}) {
    // const {
    //     offerPrint: visible,
    //     setOfferPrint,
    // } = useMaintenanceModel();
    // const [offerPrint, setOfferPrint] = useState<boolean>(false)
    //遍历商品td
    const mapGoods = (goods) => {
        return columns.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex], goods)
            : goods[item.dataIndex]}</td>)
    }
    //遍历项目td
    const itemGoods = (goods) => {
        return itemClumns?.filter(item => item).map((item, index) => <td key={index}>{item.render ? item.render(goods[item.dataIndex], goods)
            : goods[item.dataIndex]}</td>)
    }
    const print = () => {
        const el = document.getElementById('ci-offerPrint-print-container');
        const iframe: any = document.createElement('IFRAME');
        let doc: any = null;
        document.body.appendChild(iframe);
        doc = iframe.contentWindow.document;
        // 引入打印的专有CSS样式，根据实际修改
        doc.write(`<LINK rel="stylesheet" type="text/css" href="./ciOfferPrintStyle.css">`);
        doc.write(el?.innerHTML);
        doc.close();
        // 获取iframe的焦点，从iframe开始打印
        iframe.contentWindow.focus();
        iframe.onload = () => {
            iframe.contentWindow.print();
            document.body.removeChild(iframe);
        };
        if (navigator.userAgent.indexOf("MSIE") > 0) {
            document.body.removeChild(iframe);
        }
        onConfirm()
    }
    const confirmPrint = async () => {
        await printNum?.();
        await print();
    }
    return <Modal
        title={title}
        width={1200}
        visible={visible}
        onCancel={onClose}
        footer={[
            <Button className="btn-item" shape="round" onClick={onClose} key="back"> 取消</Button>,
            <Button className="btn-item" shape="round" onClick={print} key="preview">打印预览</Button>,
            <Button className="btn-item" shape="round" onClick={confirmPrint} key="submit">确认打印</Button>,
            // <Button type="link" onClick={setTitle} key="setPrintName">设置</Button>
        ]}
    >
        {visible && <div id="ci-offerPrint-print-container">
            <div className="offerPrint-container">
                <div className="title">
                    <h2>{headerTitle.title}</h2>
                    {/* <h2>Asia-Africa International FZE</h2>
                    <h2>报价单</h2> */}
                </div>
                <ul className="offerPrint-header" style={{ justifyContent: 'flex-start' }}>
                    {printShow.header && printShow.header.map((item, index) => {
                        return <li className={item.className ?? ""} key={index} style={{ width: (index == 3 || index == 4) ? '25%' : '50%' }}>{item.fieldLabel}{item.fieldValue}</li>
                    })}
                </ul>
                <table className="mingxi">
                    <tbody>
                        <tr className="print-center" >
                            {columns && columns.filter(item => item).map((item, index) =>
                                <td key={index} style={{ textAlign: 'center' }}>{item.title}</td>
                            )}
                        </tr>
                        {dataSource && dataSource.length > 0 &&
                            dataSource.map((item, index) =>
                                <tr key={index} className="print-center">
                                    {mapGoods(item)}
                                </tr>
                            )}
                        {itemDataSource && itemDataSource.length > 0 &&
                            itemDataSource.map((item, index) =>
                                <tr key={index} className="print-center">
                                    {itemGoods(item)}
                                </tr>
                            )}
                        <tr className="print-left">
                            <td colSpan={2} className="border-right-none">{printShow.SaleTotal ? `销售金额：${printShow.SaleTotal['numAll']}项` : ""}</td>
                            <td colSpan={2}>{printShow.SaleTotal ? printShow.SaleTotal['receivedAmount'] : ""}</td>
                            <td colSpan={3} className="border-right-none">{printShow.Total ? `${printShow.Total['label'] ?? '应收金额'}：${printShow.Total['numAll']}项` : ""}</td>
                            <td colSpan={columns.length - 7}>{printShow.Total ? printShow.Total['receivedAmount'] : ""}</td>
                        </tr>
                        {printShow.DiscountTotal && <tr className="print-left">
                            <td colSpan={2} className="border-right-none">{printShow.DiscountTotal ? `优惠金额：${printShow.DiscountTotal['numAll']}项` : ""}</td>
                            <td colSpan={2}>{printShow.Total ? printShow.DiscountTotal['receivedAmount'] : ""}</td>
                            <td colSpan={3} className="border-right-none">{printShow.ReceivedTotal ? `已收金额：${printShow.ReceivedTotal['numAll']}项` : ""}</td>
                            <td colSpan={columns.length - 7}>{printShow.ReceivedTotal ? printShow.ReceivedTotal['receivedAmount'] : ""}</td>
                        </tr>}
                        <tr className="print-left">
                            <td colSpan={columns.length} className="border-right-none">
                                人民币（大写）：{printShow?.Total['receivedAmountChina']}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <div className="footer">
                    <ul className="footer-people">
                        {printShow.footerPeople?.map((item, index) => {
                            return <li key={index}>{item.fieldLabel}{item.fieldValue}</li>
                        })}
                    </ul>

                    {printShow.note && <p className="note-footer">{printShow?.note?.fieldLabel}{printShow?.note?.fieldValue}</p>}
                </div>
            </div>
        </div>}
    </Modal>
}
import React, { useState, useEffect } from 'react'
import { YhOfferPrint } from "../YhPrint"
import { purchaseRoService } from './purchaseRoService';
import { IDetailList, IPurchaseRoDetail } from './purchaseRoType';
import { numberParseChina, formatNum } from '@/utils/utils';
import { fmoney } from "../../utils";
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
import { usePrintModel } from "@/views/receipt/outWarehouse/view/printModel";
import { PurchaseReturnStatus } from "../../dictMap";

export function CiPurcharseReturnPrint() {
    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const [formVal, setFormVal] = useState<IPurchaseRoDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IDetailList[]>([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState(initDesc);
    const [itemTotal, setItemTotal] = useState(initDesc);
    const [total, setTotal] = useState(totalDesc);
    const { id, code, ciPurcharseReturnPV, setCiPurcharseReturnPV } = usePrintModel()

    useEffect(() => {
        ciPurcharseReturnPV && getPoDetailOne()
    }, [ciPurcharseReturnPV])

    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await purchaseRoService.one(code);
        let { details, ...formVal } = retData;

        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }

        let newdetails = details?.sort((a, b) => a.materialCode?.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a, b) => a.shelfCode?.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => {
            newMaintenanceTotal.total += Number(item.num) * Number(item.price);
            newMaintenanceTotal.discount = item.discountRate;
            newTotal.num += Number(item.num);
            let { price, discountRate, amount } = item;

            return {
                ...item,
                No: index + 1,
                notAmount: Number(item.num) * Number(item.price),
                price: fmoney(price, 2),
                taxPrice: fmoney(formatNum(Number(price) * Number(discountRate) / 100), 2),
                amount: fmoney(amount, 2)
            }
        })

        //这里做最后一行汇总信息展示
        // newdetails?.push({
        //     num: newTotal.num,
        //     No: "",
        //     materialCode: "合计",
        //     amount: fmoney(formVal.totalAmount, 2)
        // })
        if (retData) {
            setMaintenanceList(newdetails ?? []);
            setFormVal({ ...formVal, })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };
    const printNum = async () => {
        await purchaseRoService.printNum(id);
        //await loadData()
    }
    const onCancel = () => {
        setCiPurcharseReturnPV(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }

    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '仓库',
            dataIndex: 'warehouseName',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
    ]

    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "采购退单号：",
                fieldValue: formVal?.returnCode,
                className: "w30"
            },
            {
                fieldLabel: "制单日期：",
                fieldValue: formVal.createTime ? moment(formVal.createTime).format("YYYY-MM-DD HH:mm") : ""
            },
            {
                fieldLabel: "打印日期：",
                fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                // className: "li-right"
            },
            {
                fieldLabel: "采购单号：",
                fieldValue: maintenanceList?.[0]?.purchaseCode,
                className: "w30"
            },
            {
                fieldLabel: "供应商名称：",
                fieldValue: formVal?.supplierName,
            },
            {
                fieldLabel: "",
                fieldValue: "",
                className: "li-right"
            },
        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser,
                className: "w25"
            },
            {
                fieldLabel: "销售：",
                fieldValue: "",
                className: "w15"
            },
            {
                fieldLabel: "出纳：",
                fieldValue: "",
                className: "w15"
            },
            {
                fieldLabel: "会计：",
                fieldValue: "",
                className: "w15"
            },
            // {
            //     fieldLabel: "仓库：",
            //     fieldValue: maintenanceList[0]?.warehouseName,
            //     className: "w30"
            // },
            // {
            //     fieldLabel: "客户：",
            //     fieldValue: formVal?.customerName
            // },

        ],
        note: {
            fieldLabel: "备注:",
            fieldValue: formVal?.note
        },
        Total: {
            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.payableAmount ?? 0),
            receivedAmount: fmoney(formVal?.payableAmount, 2)
        },

        // Total: [
        //     {
        //         fieldLabel: "大写金额 THE SUM OF：",
        //         fieldValue: numberParseChina(formVal?.receivedAmount as string)
        //     },
        //     {
        //         fieldLabel: "结算类型 Payment Type：",
        //         fieldValue: getTreeNodeName(formVal?.payType as string)
        //     },
        // ],
    }
    const headerTitle = {
        title: `${formVal.bentityName}退货单`
    }
    return (
        <YhOfferPrint
            title="订货单打印详情"
            type=""
            visible={ciPurcharseReturnPV}
            onClose={onCancel}
            columns={columns}
            printNum={printNum}
            dataSource={maintenanceList}
            printShow={printShow}
            headerTitle={headerTitle}
            onConfirm={() => null}
            itemClumns
            itemDataSource
        />
    )
}
import React, { useState, useEffect } from 'react'
import { fmoney } from "../../utils";
import { SettlePrint } from "../settlePrint"
import { maintenanceService } from "./ordersService"
import { IMaintenanceField } from './maintenanceType'
import moment from 'moment';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";
import { SettleType } from "../../dictMap";

export function CiMaintenanceReturnPrint() {
    const [formVal, setFormVal] = useState<IMaintenanceField>();
    const [maintenanceList, setMaintenanceList] = useState<IMaintenanceField[]>([]);
    const [itemList, setItemList] = useState([]);
    const { id, ciMaintenanceReturnPV, setCiMaintenanceReturnPV } = usePrintModel()

    useEffect(() => {
        if (ciMaintenanceReturnPV) {
            getDetailOne()
        }
    }, [ciMaintenanceReturnPV])

    const getDetailOne = async () => {
        let { retData } = await maintenanceService.returnOrderPrintOne(id);
        let { returnItemList, returnMaintainMaterialList, orderInfo, enterTime, returnOrderInfo, ...from } = retData
        // receivableTotalAmount = repairFormatNum(receivableTotalAmount)
        const newItemDetails = returnItemList?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        const newMaterialDetails = returnMaintainMaterialList?.map((item, index) => {
            return {
                ...item,
                No: index + 1
            }
        })
        if (retData) {
            setMaintenanceList(newMaterialDetails ?? []);
            setItemList(newItemDetails ?? []);
            // setFormVal({ ...from, receivableTotalAmount, receivableTotalAmountCN: numberParseChina(receivableTotalAmount), enterTime: enterTime })
            setFormVal({ ...from, ...orderInfo, enterTime, ...returnOrderInfo, })
        }
    }
    const printNum = async () => {
        await maintenanceService.returnOrderPrintNum(id);
        //await loadData();
    }
    const onClose = () => {
        setCiMaintenanceReturnPV(false)
    }
    const itemClumns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '项目编号',
            dataIndex: 'itemCode',
        },
        {
            title: '项目名称',
            dataIndex: 'itemName',
        },
        {
            title: '工时数',
            dataIndex: 'itemWorkHours',
        },
        {
            title: '工时单价',
            dataIndex: 'workHoursPrice',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '折扣',
            dataIndex: 'itemDiscount',
            render: (text) => text ? text + "%" : "100%"
        },
        {
            title: '优惠',
            dataIndex: 'preferentialAmount',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '工时费',
            dataIndex: 'actualAmount',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '备注',
            dataIndex: 'remark',
            render: (text) => <span style={{ width: '150px' }}>{text}</span>
        },
    ]
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'materialNumber',
        },
        {
            title: '单价',
            dataIndex: 'unitPrice',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '折扣',
            dataIndex: 'materialDiscount',
            render: (text) => text ? text + "%" : "100%"
        },
        {
            title: '金额',
            dataIndex: 'actualAmount',
            render: (text) => fmoney(text, 2)
        },
        {
            title: '备注',
            dataIndex: 'remark',
            render: (text) => <span style={{ width: '150px' }}>{text}</span>
        },
    ]
    let IHeader = [
        {
            fieldLabel: "退单编号:",
            fieldValue: formVal?.returnCode,
            className: "w35"
            // fieldValue: `${formVal.returnCode}(${getTreeNodeName(formVal?.status)})`
        },
        {
            fieldLabel: "单据编号:",
            fieldValue: formVal?.orderCode,
            className: "w40"
        },
        {
            fieldLabel: "结算类型:",
            fieldValue: SettleType.get(formVal?.settlementType ?? ""),
            className: "w25"
        },
        {
            fieldLabel: "结算方:",
            fieldValue: formVal?.settlementParty, //getTreeNodeName(formVal.payType as string)
            className: "w75"
        }, {
            fieldLabel: "打印日期:",
            fieldValue: moment(new Date()).format("YYYY-MM-DD HH:mm"),
            className: "w25"
        },
    ]
    return (
        <SettlePrint
            title={`云豪车服（科技）服务有限公司修理结算单`}
            visible={ciMaintenanceReturnPV}
            onClose={onClose}
            printNum={printNum}
            columns={columns}
            itemClumns={itemClumns}
            itemDataSource={itemList}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printHeader={formVal}
            printFooter={formVal}
            IHeader={IHeader}
            onConfirm={() => null}
            printShow
        />

    )
}
import { httpRequest } from '@/utils/HttpRequest';
import { IPage } from '@/types/AppTypes';
import {
	ISCMSaleRoSearch,
	ISCMSaleRoDetail,
	ISCMSaleRoInsert,
	ISCMSaleRoUpdate,
	ISCMSaleRoStatistics,
	ISCMSaleSoGoodsSearch,
	ISCMSaleSoGoodsDetailed,
	ISCMROCancelReason,
	ISCMRoApproval,
	ISCMIrregularSettle,
} from './saleIrregularTypes';

export interface ISCMPage<T, S> extends IPage<T> {
	statistics: S;
}

class SaleIrregularService {
	//销售退货单
	async page(searchVo: ISCMSaleRoSearch) {
		// const apiUrl = "/scm2/sale/ro/listPage";
		const apiUrl = '/scm2/unrelate/sreturn/page';
		return await httpRequest.post<IPage<ISCMSaleRoDetail>>(apiUrl, searchVo);
	}

	// 列表统计
	async statistic(params: object) {
		const apiUrl = '/scm2/unrelate/sreturn/statistics';
		return await httpRequest.post<ISCMSaleRoStatistics>(apiUrl, params);
	}
	async one(id: string) {
		const apiUrl = `/scm2/unrelate//sreturn/one/${id}`;
		return await httpRequest.get<ISCMSaleRoDetail>(apiUrl);
	}
	async insert(insertVo: ISCMSaleRoInsert) {
		const apiUrl = '/scm2/unrelate/sreturn/insert';
		return await httpRequest.post<string>(apiUrl, insertVo, { timeout: 300000 });
	}
	async update(updateVo: ISCMSaleRoUpdate) {
		const apiUrl = '/scm2/unrelate/sreturn/update';
		return await httpRequest.post<boolean>(apiUrl, updateVo);
	}

	// 提交
	async submit(id: string) {
		const apiUrl = `/scm2/unrelate/sreturn/submit/${id}`;
		return await httpRequest.get<boolean>(apiUrl);
	}

	// 列表导出
	async export(searchVo: ISCMSaleRoSearch) {
		const appPageUrl = '/scm2/unrelate/sreturn/export';
		// const appPageUrl = '/scm2/unrelate/sreturn/export-list';
		return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
	}

	// 明细导出
	async detailExport(searchVo: ISCMSaleRoSearch) {
		const apiUrl = '/scm2/unrelate/sreturn/export-detail-list';
		return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000 });
	}

	// 明细商品分页查询(销退)
	async saleSoGoodspage(searchVo: ISCMSaleSoGoodsSearch) {
		const apiUrl = '/scm2/unrelate/sreturn/material/page';
		return await httpRequest.post<IPage<ISCMSaleSoGoodsDetailed>>(apiUrl, searchVo);
	}

	// 明细商品分页查询(采退)
	async purchaseRoGoodspage(searchVo: ISCMSaleSoGoodsSearch) {
		const apiUrl = '/scm2/inventory/details/page';
		return await httpRequest.post<IPage<ISCMSaleSoGoodsDetailed>>(apiUrl, searchVo);
	}

	// 下载模板
	async downloadTpl() {
		const appPageUrl = '/scm2/unrelate/sreturn/download';
		return await httpRequest.getFile(appPageUrl);
	}

	// 单条数据明细导出
	async downloadDetail(code: string) {
		const appPageUrl = `/scm2/unrelate/sreturn/export-detail-list/${code}`;
		return await httpRequest.getFile(appPageUrl);
	}
	//作废
	async disable(params: ISCMROCancelReason) {
		const apiUrl = `/scm2/sale/ro/disable`;
		return await httpRequest.post<boolean>(apiUrl, params);
	}

	//转接库房
	async transferWarehouse(id: string) {
		const apiUrl = `/scm2/unrelate/sreturn/transfer-warehouse/${id}`;
		return await httpRequest.get<boolean>(apiUrl);
	}

	//销售退货单结算
	async settle(settleVo: ISCMIrregularSettle) {
		const apiUrl = `/scm2/unrelate/sreturn/settle`;
		return await httpRequest.post<boolean>(apiUrl, settleVo);
	}

	// 打印次数
	async printNum(id: string) {
		const apiUrl = `/scm2/unrelate/sreturn/update/print-num/${id}`;
		return await httpRequest.post<boolean>(apiUrl);
	}

	// 审核
	async approval(params: ISCMRoApproval) {
		const apiUrl = '/scm2/unrelate/sreturn/approval';
		return await httpRequest.post<boolean>(apiUrl, params);
	}
}

export const saleIrregularService = new SaleIrregularService();

import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/AppTypes";
import { ISCMSaleRoSearch, ISCMSaleRoDetail, ISCMSaleRoInsert, ISCMSaleRoUpdate, ISCMSaleRoStatistics, ISCMSaleSoGoodsSearch, ISCMSaleSoGoodsDetailed, ISCMROCancelReason } from "./saleRoTypes";


class SaleRoService {
    //销售退货单
    async page(searchVo: ISCMSaleRoSearch) {
        const apiUrl = "/scm2/sale/ro/listPage";
        return await httpRequest.post<IPage<ISCMSaleRoDetail>>(apiUrl, searchVo);
    }
    //销售退货统计
    async getStatistics(searchVo: ISCMSaleRoSearch) {
        const apiUrl = "/scm2/sale/ro/statistics";
        // const apiUrl = "/scm2/sale/so/statistics";
        return await httpRequest.post<ISCMSaleRoStatistics>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/scm2/sale/ro/findByIdOrCode/${id}`;
        return await httpRequest.get<ISCMSaleRoDetail>(apiUrl);
    }
    async insert(insertVo: ISCMSaleRoInsert) {
        const apiUrl = "/scm2/sale/ro/insert";
        return await httpRequest.post<string>(apiUrl, insertVo, { timeout: 300000 });
    }
    async update(updateVo: ISCMSaleRoUpdate) {
        const apiUrl = "/scm2/sale/ro/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }

    // 列表导出
    async export(searchVo: ISCMSaleRoSearch) {
        const appPageUrl = "/scm2/sale/ro/export";
        return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
    }

    // 明细导出
    async detailExport(searchVo: ISCMSaleRoSearch) {
        const apiUrl = '/scm2/sale/ro/details/export'
        return await httpRequest.postFile(apiUrl, searchVo, { timeout: 300000 })
    }

    //待选则销售明细商品分页查询
    async saleSoGoodspage(searchVo: ISCMSaleSoGoodsSearch) {
        const apiUrl = "/scm2/sale/so/detail/findDetailPage";
        return await httpRequest.post<IPage<ISCMSaleSoGoodsDetailed>>(apiUrl, searchVo);
    }
    async downloadTpl() {
        const appPageUrl = "/scm2/sale/ro/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }
    async downloadDetail(returnCode: string, shopId: string) {
        const appPageUrl = `/scm2/sale/ro/exportDetails/${returnCode}/${shopId}`;
        return await httpRequest.getFile(appPageUrl);
    }
    //作废
    async disable(params: ISCMROCancelReason) {
        const apiUrl = `/scm2/sale/ro/disable`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }

    //转接库房
    async transferWarehouse(id: string) {
        const apiUrl = `/scm2/sale/ro/transferWarehouse/${id}`;
        return await httpRequest.get<boolean>(apiUrl);
    }

    //销售退货增加打印次数
    async printNum(id: string) {
        const apiUrl = `/scm2/sale/ro/update/print-num/${id}`;
        return await httpRequest.post<boolean>(apiUrl);
    }

}

export const saleRoService = new SaleRoService();
import BigNumber from 'bignumber.js'

// 针对平厂家金额 可以开正数负数得情况
export function repairFormatNum(num: string | number | undefined) {
    // debugger;
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.00'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|\.\-]/g, '')  //清除字符串中的非数字非.字符

    if (/^0+/)      //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num)) //为整数字符串在末尾添加.00
        num += '.00'
    if (/^\./.test(num)) //字符以.开头时,在开头添加0
        num = '0' + num
    num += '001'        //在字符串末尾补零
    // let newNum = num.match(/\d+\.\d{3}/);
    // return newNum ? (+newNum[0]).toFixed(2) : '0.00';
    // if (+num <= 0) {
    //     return `-${(+num).toFixed(2)}`
    // } else {
    return (+num).toFixed(2)
    // }
}


// 例如：
// 12345格式化为12,345.00
// 12345.6格式化为12,345.60
// 12345.67格式化为 12,345.67
// 只留两位小数。
// 回来后写了个格式化函数。可以控制小数位数，自动四舍五入。 代码如下：
export function fmoney(s: any, n: number) {
    n = n > 0 && n <= 20 ? n : 2;
    s = parseFloat((s + "").replace(/[^\d\.-]/g, "")).toFixed(n) + "";
    var l = s.split(".")[0].split("").reverse(), r = s.split(".")[1];
    let t: string = "";
    l.forEach((item: string, index: number) => {
        t += l[index] + ((index + 1) % 3 == 0 && (index + 1) != l.length ? "," : "");
    });
    // for (i = 0; i < l.length; i++) {
    //     t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? "," : "");
    // }
    return t.split("").reverse().join("") + "." + r;
}

/**
 * 数字补零
 */
export function formatNum(num: string | number | undefined) {
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.00';
    }
    if (typeof num === 'number') {
        num = String(num)
    }
    // let isMinus = num.indexOf("-") > -1;

    num = num.replace(/[^0-9|\.]/g, '');  //清除字符串中的非数字非.字符

    if (/^0+/)      //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num)) //为整数字符串在末尾添加.00
        num += '.00';
    if (/^\./.test(num)) //字符以.开头时,在开头添加0
        num = '0' + num;
    num += '001';        //在字符串末尾补零
    return (+num).toFixed(2);
    // return (+(isMinus ? "-" + num : num)).toFixed(2);
}


//合并打印单中配件的相同值合并，数量金额叠加 方法2
export const mergeRepeatArr = async (arr: any[], singleTotal: string = "amount") => {
    const mergeRepeatList: any[] = []
    await arr?.forEach((item: any) => {
        let findIndex = mergeRepeatList.findIndex(
            (newItem) => newItem.materialCode == item.materialCode && newItem.materialName == item.materialName && newItem.shelfCode == item.shelfCode && newItem.discountRate == item.discountRate && newItem.price == item.price
        )
        if (findIndex === -1) {
            mergeRepeatList.push(item)
        } else {

            let newNum = +formatNum(Number(item.num) + Number(mergeRepeatList[findIndex].num))
            //mergeRepeatList[findIndex] = { ...item, num: newNum, [singleTotal]: +formatNum(newNum * item.price * (item.discountRate / 100)) }
            mergeRepeatList[findIndex] = { ...item, num: newNum, [singleTotal]: +bigNumber.toFixed(bigNumber.multiTimes(newNum, item.price, (item.discountRate / 100)), 2) }
        }
    });
    return mergeRepeatList
}


//计算
export const bigNumber = {
    // 加
    add(x, y) {
        x = BigNumber(x).toFixed(2)
        y = BigNumber(y).toFixed(2)
        return BigNumber(x).plus(y).toNumber()
    },
    // 减
    minus(x, y) {
        x = BigNumber(x).toFixed(2)
        y = BigNumber(y).toFixed(2)
        return BigNumber(x).minus(y).toNumber()
    },
    // 乘
    times(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.times(y).toNumber()
    },
    // 除
    dividedBy(x, y) {
        x = BigNumber(x)
        y = BigNumber(y)
        return x.dividedBy(y).toNumber()
    },
    // 多个相加
    multiAdd(...params) {
        let num = BigNumber(0)
        params.forEach((x) => (num = num.plus(BigNumber(x))))
        return num.toNumber()
    },
    // 多个相乘
    multiTimes(...params) {
        let num = BigNumber(1)
        params.forEach((x) => (num = num.times(BigNumber(x))))
        return num.toNumber()
    },
    //保留小数
    toFixed(num, x) {
        return BigNumber(num).toFixed(x)
    },
}
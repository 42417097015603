import { IPrintProps, YhPrint } from '../YhPrint';
import useGlobalModel from '@/model/globalModel';
import { repairFormatNum } from "./utils";
import { Checkbox, Radio } from "antd";
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { purchasePoService } from './purchasePoService';
import { ISCMPoGoodsDetail, ISCMPurchasePoDetail } from './purchasePoTypes';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";

export enum EquilibriumEnum {
	JUST = "+0.01",
	NEGATIVE = "-0.01"
}

export function ScmPurcharsePrint() {
	const { id, scmPurcharsePV, setScmPurcharsePV, } = usePrintModel();
	const [formVal, setFormVal] = useState<ISCMPurchasePoDetail>();
	const [goodsList, setGoodsList] = useState<ISCMPoGoodsDetail[]>([]);
	const [check, setCheck] = useState<boolean>(false)
	const { user } = useGlobalModel();

	useEffect(() => {
		scmPurcharsePV && getPoDetailOne()
	}, [scmPurcharsePV])

	//获取一条数据
	const getPoDetailOne = async () => {
		let { retData } = await purchasePoService.one(id);
		let { details, ...formVal } = retData;
		// let newDetails = details.filter(item => !(item.materialCode === EquilibriumEnum.JUST || item.materialCode === EquilibriumEnum.NEGATIVE))
		if (retData) {
			setGoodsList(details.sort((a: any, b: any) => a.materialCode.localeCompare(b.materialCode, 'zh-Hans-CN', { sensitivity: 'accent' })).sort((a: any, b: any) => a.shelfCode.localeCompare(b.shelfCode, 'zh-Hans-CN', { sensitivity: 'accent' })).map((item, index) => ({ ...item, No: index + 1 })));
			setFormVal(retData);
		}
	};

	const columns = [
		{
			title: '序号',
			dataIndex: 'No',
		},
		{
			title: '配件编号',
			dataIndex: 'materialCode',
		},
		{
			title: '商品名称',
			dataIndex: 'materialName',
		},
		{
			title: '替换编号',
			dataIndex: 'replaceCode',
		},
		{
			title: '单位',
			dataIndex: 'unitName',
		},
		{
			title: '数量',
			dataIndex: 'num',
		},
		// {
		// 	title: '商品类型',
		// 	dataIndex: 'typeName',
		// },
		{
			title: '单价',
			dataIndex: 'price',
			render: (text: string | number, record: ISCMPoGoodsDetail) => `${repairFormatNum(+text * (1 - +record.discountRate))}`
		},
		{
			title: '金额',
			dataIndex: 'amount',
			render: (text: string | number) => `${repairFormatNum(text)}`
		},
		// {
		// 	title: '仓库',
		// 	dataIndex: 'warehouseName',
		// },
		{
			title: '货位',
			dataIndex: 'shelfCode',
		},
	]

	//头部与底部展示
	const printShow = {
		header: [
			{
				fieldLabel: "采购单号",
				fieldValue: formVal?.purchaseCode ?? ""
			},
			{
				fieldLabel: "制单日期",
				fieldValue: formVal?.createTime ?? ""
			},
			{
				fieldLabel: "打印时间",
				fieldValue: moment().format("YYYY-MM-DD HH:mm:ss") //当前时间
			},
			// {
			// 	fieldLabel: "支付方式",
			// 	fieldValue: formVal?.payRecords?.map((item: any) => item?.payMethodName ?? "").join() ?? "",
			// 	className: "w30"
			// },
			{
				fieldLabel: "供应商名称",
				fieldValue: formVal?.supplierName ?? "",
				className: "h34_w40"
			},
		],
		footer: [
			{
				fieldLabel: "制表人",
				fieldValue: user.userName,
				className: "w20"
			},
			{
				fieldLabel: "业务",
				fieldValue: formVal?.createUser ?? "",
				className: "w20"
			},
			{
				fieldLabel: "财务",
				fieldValue: formVal?.casher ?? "",
				className: "w20"
			},
			{
				fieldLabel: "库房",
				fieldValue: formVal?.storekeeper ?? "",
				className: "w20"
			},
			{
				fieldLabel: "结算员",
				fieldValue: formVal?.reckoner ?? "",
				className: "w20"
			},
			{
				fieldLabel: "备注",
				fieldValue: formVal?.note ?? "",
				className: "note"
			},
		],
		totalAmount: [
			// {
			// 	fieldLabel: "合计",
			// 	fieldValue: goodsList.reduce((total, next) => { total += (Number(next?.amount ?? 0)); return total }, 0),
			// },
			// {
			// 	fieldLabel: "优惠金额",
			// 	fieldValue: formVal?.discountTotalAmount,
			// },
			[{
				fieldLabel: "应付金额",
				// fieldValue: formVal?.stockIoTotalAmount,
				fieldValue: formVal?.stockIoTotalAmount,
				RMB: true
			},
			{
				fieldLabel: "",
				fieldValue: "",
				// 111
			}
			],
		]
	}
	const checkChange = (e: CheckboxChangeEvent) => setCheck(e.target.checked)

	const [paperSize, setPaperSize] = useState<number>(40);  //打印的商品条数
	const modalTitle = <>
		<span style={{ marginRight: "15px" }}>打印详情</span>
		<Radio.Group onChange={(e) => setPaperSize(e.target.value)} value={paperSize}>
			<Radio value={45}>A4</Radio>
			<Radio value={40}>10x11</Radio>
		</Radio.Group>
		<span style={{ marginRight: "30px" }}></span>
		<Checkbox checked={check} onChange={checkChange}>是否显示差额</Checkbox>
	</>
	const newGoodsList = check ? goodsList : goodsList.filter(item => !(item.materialCode === EquilibriumEnum.JUST || item.materialCode === EquilibriumEnum.NEGATIVE)).map((item, index) => ({ ...item, No: index + 1 }))
	return (
		<YhPrint
			modalTitle={modalTitle}
			title={formVal?.bentityName ?? "北京德兴志达商贸有限公司"}
			type="进货单"
			visible={scmPurcharsePV}
			onClose={() => setScmPurcharsePV(false)}
			columns={columns}
			dataSource={newGoodsList}
			printShow={printShow}
			paperSize={paperSize}
		/>
	);
}

export default ScmPurcharsePrint;

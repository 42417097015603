

// 针对平厂家金额 可以开正数负数得情况
export function repairFormatNum(num: string | number | undefined) {
    // debugger;
    if (num === undefined || num === null || isNaN(+num)) {
        return '0.00'
    }
    if (typeof num === 'number') {
        num = String(num)
    }

    num = num.replace(/[^0-9|\.\-]/g, '')  //清除字符串中的非数字非.字符

    if (/^0+/)      //清除字符串开头的0
        num = num.replace(/^0+/, '')
    if (!/\./.test(num)) //为整数字符串在末尾添加.00
        num += '.00'
    if (/^\./.test(num)) //字符以.开头时,在开头添加0
        num = '0' + num
    num += '001'        //在字符串末尾补零
    // let newNum = num.match(/\d+\.\d{3}/);
    // return newNum ? (+newNum[0]).toFixed(2) : '0.00';
    // if (+num <= 0) {
    //     return `-${(+num).toFixed(2)}`
    // } else {
    return (+num).toFixed(2)
    // }
}
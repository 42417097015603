import { httpRequest } from "@/utils/HttpRequest";
import { getBaseCiRequestUrl } from '@/utils/constants'
import { IPurchaseRoDetail } from './purchaseRoType';

const baseURL = getBaseCiRequestUrl();

class PurchaseRoService {

    async one(id) {
        const apiUrl = `/ci-storage/preturn/findById/${id}`;
        return await httpRequest.get<IPurchaseRoDetail>(apiUrl, { timeout: 300000, ...{ baseURL } });
    }

    //打印次数
    async printNum(id) {
        const apiUrl = `/ci-storage/preturn/print/${id}`;
        return await httpRequest.get(apiUrl, { timeout: 300000, ...{ baseURL } });
    }
}

export const purchaseRoService = new PurchaseRoService();
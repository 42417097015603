import { httpRequest } from "@/utils/HttpRequest";
import { getBaseCiRequestUrl } from '@/utils/constants'
import { IMaintenanceField } from './maintenanceType'

const baseURL = getBaseCiRequestUrl();

class MaintenanceService {

    async returnOrderPrintOne(id) {
        const apiUrl = `/ci/order/return/print/info/${id}`;
        return await httpRequest.post<IMaintenanceField>(apiUrl, {}, { timeout: 300000, ...{ baseURL } });
    }

    async returnOrderPrintNum(id) {
        const apiUrl = `/ci/order/return/print/count/${id}`;
        return await httpRequest.get(apiUrl, { timeout: 300000, ...{ baseURL } });
    }
}

export const maintenanceService = new MaintenanceService();
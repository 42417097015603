import { useState } from 'react'
import { createModel } from 'hox'

export const usePrintModel = createModel(function () {

    const [id, setId] = useState<string>("");
    const [code, setCode] = useState<string>("");

    const [scmSaleReturnPV, setScmSaleReturnPV] = useState<boolean>(false);
    const [scmPurcharsePV, setScmPurcharsePV] = useState<boolean>(false);
    const [scmUnrelateReturnPV, setScmUnrelateReturnPV] = useState<boolean>(false);

    const [bcmSaleReturnPV, setBcmSaleReturnPV] = useState<boolean>(false);
    const [bcmPurcharsePV, setBcmPurcharsePV] = useState<boolean>(false);
    const [bcmPresaleReturnPV, setBcmPresaleReturnPV] = useState<boolean>(false);

    const [vrmSaleReturnPV, setVrmSaleReturnPV] = useState<boolean>(false);
    const [vrmPurcharsePV, setVrmPurcharsePV] = useState<boolean>(false);
    const [vrmMaintenanceReturnPV, setVrmMaintenanceReturnPV] = useState<boolean>(false);

    const [ciSaleReturnPV, setCiSaleReturnPV] = useState<boolean>(false);
    const [ciPurcharsePV, setCiPurcharsePV] = useState<boolean>(false);
    const [ciMaintenanceReturnPV, setCiMaintenanceReturnPV] = useState<boolean>(false);

    return {
        id, setId,
        code, setCode,
        scmSaleReturnPV, setScmSaleReturnPV,
        scmPurcharsePV, setScmPurcharsePV,
        scmUnrelateReturnPV, setScmUnrelateReturnPV,
        bcmSaleReturnPV, setBcmSaleReturnPV,
        bcmPurcharsePV, setBcmPurcharsePV,
        bcmPresaleReturnPV, setBcmPresaleReturnPV,
        vrmSaleReturnPV, setVrmSaleReturnPV,
        vrmPurcharsePV, setVrmPurcharsePV,
        vrmMaintenanceReturnPV, setVrmMaintenanceReturnPV,
        ciSaleReturnPV, setCiSaleReturnPV,
        ciPurcharsePV, setCiPurcharsePV,
        ciMaintenanceReturnPV, setCiMaintenanceReturnPV,
    }
})

import React, { useState, useEffect } from 'react'
import { YhOfferPrint } from "../OfferPrint/OfferPrint"
import { saleRoService } from './saleRoService';
import { IDetailList, ISaleRoDetail } from './saleRoTypes';
import { numberParseChina, momentFormat } from '@/utils/utils';
import { fmoney, mergeRepeatArr } from "../../utils";
import { useDictModel } from '@/views/base/dict/dictModel';
import moment from 'moment';
import useGlobalModel from '@/model/globalModel';
import { usePrintModel } from "@/views/receipt/enterWarehouse/view/printModel";
import { SaleReturnStatus, SettleType } from "../../dictMap";

export type IMaintenanceAndItemType = {
    total: number
    discount: number
}
export type TotalType = {
    num: number,
    total: number
}
export function VrmSaleReturnPrint() {
    const initDesc = { total: 0, discount: 0 }
    const totalDesc = { total: 0, num: 0 }
    const { CustomerData, user: { shopName, shopId, shopCode } } = useGlobalModel()
    const [settlementPartyName, setSettlementPartyName] = useState<string>("")
    const [formVal, setFormVal] = useState<ISaleRoDetail>({});
    const [maintenanceList, setMaintenanceList] = useState<IDetailList[]>([]);
    const [maintenanceTotal, setMaintenanceTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [itemTotal, setItemTotal] = useState<IMaintenanceAndItemType>(initDesc);
    const [total, setTotal] = useState<TotalType>(totalDesc);
    const { getTreeNodeName, getTreeNodeOne } = useDictModel();
    const { id, code, vrmSaleReturnPV, setVrmSaleReturnPV } = usePrintModel()

    // const { shopAndOrg: { shopCode } } = useGlobalModel()
    useEffect(() => {
        vrmSaleReturnPV && getPoDetailOne()
    }, [vrmSaleReturnPV])
    //获取一条数据
    const getPoDetailOne = async () => {
        let { retData } = await saleRoService.one(code);
        let { details, settlementPartyCode, customerName, ...formVal } = retData;

        let newMaintenanceTotal = { ...maintenanceTotal }
        let newItemTotal = { ...itemTotal }
        let newTotal = { ...total }
        let mergeDetails = await mergeRepeatArr(details as IDetailList[]) as IDetailList[];


        let newdetails: any = mergeDetails?.map((item, index) => {
            newMaintenanceTotal.total += Number(item.num) * Number(item.price)
            newTotal.num += Number(item.num)
            let { amount } = item

            return {
                ...item,
                No: index + 1,
                price: fmoney(item.price, 2),
                notAmount: Number(item.num) * Number(item.price),
                amount: fmoney(amount, 2)
            }
        })

        if (settlementPartyCode === "settle-party.internal") {
            setSettlementPartyName(shopName)
        } else if (settlementPartyCode && (settlementPartyCode?.indexOf("man") !== -1 || settlementPartyCode?.indexOf("scn") !== -1)) {
            //let { value: customerCode } = getTreeNodeOne(settlementPartyCode as string)[0]
            // console.log(settlementPartyCode, getTreeNodeOne(settlementPartyCode as string),getFindItem(customerCode, CustomerData))
            //setSettlementPartyName(getFindItem(customerCode, CustomerData).name)
        } else {
            setSettlementPartyName(customerName as string)
        }
        //这里做最后一行汇总信息展示
        // newdetails?.push({
        //     num: newTotal.num,
        //     No: "",
        //     materialCode: "合计",
        //     amount: fmoney(formVal.totalAmount, 2)
        // })
        if (retData) {
            setMaintenanceList(newdetails as ISaleRoDetail[] ?? []);
            // setFormVal(formVal)
            setFormVal({ ...formVal, settlementPartyCode, customerName })
            setMaintenanceTotal(newMaintenanceTotal)
            setItemTotal(newItemTotal)
        }
    };
    const printNum = async () => {
        await saleRoService.printNum(id);
        //await loadData()
    }
    const onCancel = () => {
        setVrmSaleReturnPV(false)
        setMaintenanceList([]);
        setFormVal({})
        setMaintenanceTotal(initDesc)
        setItemTotal(initDesc)
    }
    const columns = [
        {
            title: '序号',
            dataIndex: 'No',
        },
        {
            title: '商品编号',
            dataIndex: 'materialCode',
        },
        {
            title: '商品名称',
            dataIndex: 'materialName',
        },
        {
            title: '单位',
            dataIndex: 'unitName',
        },
        {
            title: '数量',
            dataIndex: 'num',
        },
        {
            title: '单价',
            dataIndex: 'price',
        },
        // {
        //     // title: '折后单价 Discounted Price',
        //     title: '单价 Unit price',
        //     dataIndex: 'taxPrice',
        // },
        {
            title: '金额',
            dataIndex: 'amount',
        },
        {
            title: '货位',
            dataIndex: 'shelfCode',
        },
        {
            title: '销售单号',
            dataIndex: 'saleCode',
        }
        // {
        //     title: '分组代码',
        //     dataIndex: '',
        // },
    ]
    const XJHeader = [
        {
            fieldLabel: "车牌号：",
            fieldValue: formVal?.licensePlate
        },
        {
            fieldLabel: "底盘号：",
            fieldValue: formVal?.vin
        },
        {
            fieldLabel: "结算方式：",
            fieldValue: SettleType.get(formVal.payType as string)
        },
        {
            fieldLabel: "结算方：",
            className: "li-right",
            fieldValue: settlementPartyName,
        },
    ]
    //头部与底部展示
    const printShow = {
        header: [
            {
                fieldLabel: "销退单号:",
                fieldValue: `${formVal.returnCode}    (${SaleReturnStatus.get(formVal?.status as string)})`,
                className: "w30"
            },
            {
                fieldLabel: "客户名称:",
                fieldValue: formVal?.customerName,
                className: "w30"
            },
            {
                fieldLabel: moment(new Date()).format("YYYY-MM-DD HH:mm"),
                fieldValue: "",
                className: "li-right w20"

            },
            {
                fieldLabel: "联系人：",
                fieldValue: formVal?.contact
            },
            {
                fieldLabel: "电话：",
                fieldValue: formVal?.phone
            },
            {
                fieldLabel: "单据性质：",
                fieldValue: "销售退货单"
            },
            {
                fieldLabel: "销售日期：",
                fieldValue: momentFormat(formVal?.createTime as string),
                className: "li-right"
            },
            {
                fieldLabel: "销售单号:",
                fieldValue: maintenanceList?.[0]?.saleCode ?? '',
                className: "w30"
            },
            ...(shopCode === "SHOP-M-00000017" || shopCode === "SHOP-M-00000018" ? XJHeader : [])
        ],
        footerPeople: [
            {
                fieldLabel: "制表人：",
                fieldValue: formVal?.createUser
            },
            {
                fieldLabel: "销售：",
                fieldValue: ""
            },
            {
                fieldLabel: "出纳：",
                fieldValue: ""
            },
            {
                fieldLabel: "仓库：",
                fieldValue: maintenanceList[0]?.warehouseName
            },
            {
                fieldLabel: "客户：",
                fieldValue: ""
            },
            // {
            //     fieldLabel: "客户：",
            //     fieldValue: formVal?.customerName
            // },

        ],
        note: [{
            fieldLabel: "备注:",
            fieldValue: formVal?.fmsNote
        }],
        Total: {

            numAll: maintenanceList.length,
            receivedAmountChina: numberParseChina(formVal?.receivedAmount as number),
            receivedAmount: fmoney(formVal?.receivedAmount, 2),
            label: "应付金额"
        },
        // Total: [
        //     {
        //         fieldLabel: "大写金额 THE SUM OF：",
        //         fieldValue: numberParseChina(formVal?.receivedAmount as string)
        //     },
        //     {
        //         fieldLabel: "结算类型 Payment Type：",
        //         fieldValue: getTreeNodeName(formVal?.payType as string)
        //     },
        // ],
    }
    const headerTitle = {
        // title: "天津欧豪富港汽车贸易有限公司销售退货单"
        title: `${formVal.bentityName as string ?? ''}销售退货单`

    }
    return (
        <YhOfferPrint
            title="销售退货单打印详情"
            type=""
            visible={vrmSaleReturnPV}
            onClose={onCancel}
            printNum={printNum}
            columns={columns}
            dataSource={maintenanceList.filter(item => item.num !== 0)}
            printShow={printShow}
            headerTitle={headerTitle}
        />
    )
}
import { httpRequest } from "@/utils/HttpRequest";
import {
    IPurchasePoSearch,
    IPurchasePoDetail,
    IPurchasePoFields,
    IPurchasePoTurnSale,
    Detailed,
    IPurchasePoList,
    IPurchasePoSettleAndWhConfirm,
    IPurchasePoStatistics,
    IMaterialWsInfo
} from "./purchasePoType"

class PurchasePoService {
    async page(searchVo: IPurchasePoSearch) {
        const apiUrl = "/vrm2/purchase/findPage";
        return await httpRequest.post<IPurchasePoList>(apiUrl, searchVo);
    }
    async total(searchVo: IPurchasePoSearch) {
        const apiUrl = "/vrm2/purchase/total";
        return await httpRequest.post<IPurchasePoStatistics>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/vrm2/purchase/findById/${id}`;
        return await httpRequest.get<IPurchasePoDetail>(apiUrl);
    }

    async insert(insertVo: IPurchasePoFields) {
        const apiUrl = "/vrm2/purchase/insert";
        return await httpRequest.post<IPurchasePoDetail>(apiUrl, insertVo);
    }
    async update(updateVo: IPurchasePoFields) {
        const apiUrl = "/vrm2/purchase/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/vrm2/purchase/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: IPurchasePoFields) {
        const apiUrl = "/vrm2/purchase/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //作废
    async invalidate(params: IPurchasePoSettleAndWhConfirm) {
        const apiUrl = "/vrm2/purchase/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/vrm2/purchase/detail/downloadTpl";
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //库房确认
    async warehousing(params: IPurchasePoSettleAndWhConfirm) {
        const apiUrl = "/vrm2/purchase/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //采购结算
    async settle(params: IPurchasePoSettleAndWhConfirm) {
        const apiUrl = "/vrm2/purchase/settlePro";
        // const apiUrl = "/vrm2/purchase/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //采购补单
    async supplementInsert(insertVo: IPurchasePoFields) {
        const apiUrl = "/vrm2/purchase/supplementInsert";
        return await httpRequest.post<IPurchasePoDetail>(apiUrl, insertVo);
    }
    //转接销售单
    async transfer(insertVo: IPurchasePoFields) {
        const apiUrl = "/vrm2/purchase/transfer";
        return await httpRequest.post<boolean>(apiUrl, insertVo);
    }
    //详情导出
    async detailsExport(params: IPurchasePoFields) {
        const apiUrl = "/vrm2/purchase/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //打印次数
    async printNum(id: string) {
        const apiUrl = `/vrm2/purchase/print/${id}`;
        return await httpRequest.get(apiUrl);
    }
    //查询门店下配件在某仓库下的货位
    async getMaterialShelf(obj: any) {
        const apiUrl = `/vrm2/purchase/getMaterialShelf`;
        return await httpRequest.post<IMaterialWsInfo>(apiUrl, obj);
    }
}

export const purchasePoService = new PurchasePoService();
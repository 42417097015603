import { httpRequest } from '@/utils/HttpRequest'
import { IPage } from "@/types/AppTypes";
import { ISCMSaleSoSearch, ISCMSaleSoDetail, ISCMSaleSoInsert, ISCMSaleSoUpdate, ISCMSaleSoStatistics, ISCMSaleSoInsertReplenish, ISCMSaleBindSearch, ISCMSaleBindInsert, ISCMSaleBindList, ISCMSaleDispatch, ISCMSaleDispatchs, ISCMSoCancelSearch, ISCMSoCancelDetail, IGiftsDetailsSearch, IGiftsDetailsFields, IGiftsDetailsStatistic, IGiftsDetailsList, ISCMSOCancelReason } from "./saleSoTypes";

export interface ISCMPage<T, S> extends IPage<T> {
    statistics: S
}

export interface submitType {
    shopId: string,
    id: string,
    isPull?: string,
    customerName?: string,
    warehouseName?: string
}

class SaleSoService {
    //销售单
    async page(searchVo: ISCMSaleSoSearch) {
        const apiUrl = "/scm2/sale/so/listPage";
        // const apiUrl = "/scm2/sale/so/listPage";
        return await httpRequest.post<ISCMPage<ISCMSaleSoDetail, ISCMSaleSoStatistics>>(apiUrl, searchVo);
    }
    async oldListPage(searchVo: ISCMSaleSoSearch) {
        const apiUrl = "/scm2/sale/so/oldListPage";
        // const apiUrl = "/scm2/sale/so/listPage";
        return await httpRequest.post<ISCMPage<ISCMSaleSoDetail, ISCMSaleSoStatistics>>(apiUrl, searchVo);
    }
    //销售单
    async getStatistics(searchVo: ISCMSaleSoSearch) {
        const apiUrl = "/scm2/sale/so/statistics";
        // const apiUrl = "/scm2/sale/so/statistics";
        return await httpRequest.post<ISCMSaleSoStatistics>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/scm2/sale/so/findByIdOrCode/${id}`;
        return await httpRequest.get<ISCMSaleSoDetail>(apiUrl);
    }
    async insert(insertVo: ISCMSaleSoInsert) {
        const apiUrl = "/scm2/sale/so/insert";
        return await httpRequest.post<string>(apiUrl, insertVo, { timeout: 300000 });
    }
    async update(updateVo: ISCMSaleSoUpdate) {
        const apiUrl = "/scm2/sale/so/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    // 销售单赠新增接口
    async presentInsert(updateVo: ISCMSaleSoInsert) {
        const apiUrl = "/scm2/sale/so/presentInsert";
        return await httpRequest.post<string>(apiUrl, updateVo);
    }
    // 销售单赠编辑接口
    async presentUpdate(updateVo: ISCMSaleSoUpdate) {
        const apiUrl = "/scm2/sale/so/presentUpdate";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //销售单 买赠明细查询接口 做单赠详情明细使用
    async giftsDetails(updateVo: IGiftsDetailsSearch) {
        const apiUrl = "/scm2/sale/so/giftsDetails";
        return await httpRequest.post<IGiftsDetailsList>(apiUrl, updateVo);
    }
    //销售单 赠品类型明细查询
    async AnotherGiftsDetails(updateVo: IGiftsDetailsSearch) {
        const apiUrl = "/scm2/sale/so/AnotherGiftsDetails";
        return await httpRequest.post<IGiftsDetailsList>(apiUrl, updateVo);
    }
    //销售单 单赠出库接口
    async presentWhConfirm(id: string) {
        const apiUrl = `/scm2/sale/so/presentWhConfirm/${id}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    //销售单转退货新增
    async turnBack(id: string) {
        const apiUrl = `/scm2/sale/so/turnBack/${id}`;
        return await httpRequest.post<boolean>(apiUrl);
    }
    //补单
    async insertReplenish(insertVo: ISCMSaleSoInsertReplenish) {
        const apiUrl = "/scm2/sale/so/insertReplenish";
        return await httpRequest.post<string>(apiUrl, insertVo);
    }

    //作废
    async disable(params: ISCMSOCancelReason) {
        const apiUrl = `/scm2/sale/so/disable`;
        return await httpRequest.post<boolean>(apiUrl, params);
    }

    async downloadTpl() {
        const appPageUrl = "/scm2/sale/so/detail/downloadTpl";
        return await httpRequest.getFile(appPageUrl);
    }

    async downloadPriceTpl() {
        const appPageUrl = "/scm2/sale/so/writeExcelTemplate";
        return await httpRequest.postFile(appPageUrl);
    }

    //导出
    async export(searchVo: ISCMSaleSoSearch) {
        const appPageUrl = "/scm2/sale/so/export";
        return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
    }
    //列表明细导出
    async detailsExport(searchVo: ISCMSaleSoSearch) {
        const appPageUrl = "/scm2/sale/so/details/export";
        return await httpRequest.postFile(appPageUrl, searchVo, { timeout: 300000 });
    }
    //导出销售单明细
    async exportDetail(id: string, shopId: string) {
        const appPageUrl = `/scm2/sale/so/exportDetails/${id}/${shopId}`;
        return await httpRequest.getFile(appPageUrl);
    }

    /**
     * 绑定单
     */
    async saleBindPage(searchVo: ISCMSaleBindSearch) {
        const appPageUrl = "/scm2/sale/bind/listPage";
        return await httpRequest.post<IPage<ISCMSaleBindList>>(appPageUrl, searchVo);
    }
    async saleBindInsert(insertVo: ISCMSaleBindInsert) {
        const appPageUrl = "/scm2/sale/bind/insert";
        return await httpRequest.post<boolean>(appPageUrl, insertVo);
    }
    //解绑
    async unbund(id: string) {
        const appPageUrl = `/scm2/sale/bind/unbind/${id}`;
        return await httpRequest.get<boolean>(appPageUrl);
    }
    //导出绑定单
    async bindExport(searchVo: ISCMSaleBindSearch) {
        const appPageUrl = "/scm2/sale/bind/export";
        return await httpRequest.postFile(appPageUrl, searchVo);
    }
    //提交销售单
    async submitSaleSo(obj: submitType) {
        const appPageUrl = `/scm2/sale/so/submit`;
        return await httpRequest.post<boolean>(appPageUrl, obj);
    }

    //销售单库房确认
    async whConfirm(id: string) {
        const appPageUrl = `/scm2/sale/so/whConfirm/${id}`;
        return await httpRequest.get<boolean>(appPageUrl);
    }

    //销售单转退货单
    async shift(id: string) {
        const appPageUrl = `/scm2/sale/so/turnBack/${id}`;
        return await httpRequest.get<string>(appPageUrl);
    }


    //销售单的取消结算明细
    async soCancel(searchVo: ISCMSoCancelSearch) {
        const apiUrl = "/scm2/sale/so/cancel";
        return await httpRequest.post<IPage<ISCMSoCancelDetail>>(apiUrl, searchVo);
    }

    /**
     * 派工
     */
    async disptachSale(arr: ISCMSaleDispatchs[]) {
        const appPageUrl = `/scm2/sale/so/batch/choice`;
        return await httpRequest.post<boolean>(appPageUrl, arr);
    }
    async confrimSale(obj: object) {
        const appPageUrl = `/scm2/sale/so/batchConfirm`;
        return await httpRequest.post<boolean>(appPageUrl, obj);
    }
    //打印次数
    async print(id: string) {
        const appPageUrl = `/scm2/sale/so/print/${id}`;
        return await httpRequest.get<string>(appPageUrl);
    }
    //修改备注
    async editNote(obj: { id: string, note: string }) {
        const appPageUrl = `/scm2/sale/so/updateNote`;
        return await httpRequest.post<boolean>(appPageUrl, obj);
    }
    //整单退货
    async fullOrderReturn(obj: { id: string, returnReason: string, shopId: string }) {
        const appPageUrl = `/scm2/sale/so/fullOrderReturn`;
        return await httpRequest.post<string>(appPageUrl, obj);
    }
    async toExamine(id: string) {
        const appPageUrl = `/scm2/sale/so/toExamine/${id}`;
        return await httpRequest.get<string>(appPageUrl);
    }

    async getApprovalUser(obj: { shopId: string, userName: string }) {
        const appPageUrl = `/fms/approval_flow/getApprovalUser`;
        return await httpRequest.post<string>(appPageUrl, obj);
    }

}

export const saleSoService = new SaleSoService();
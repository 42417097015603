import { httpRequest } from "@/utils/HttpRequest";
import {
    ISaleRoSearch,
    ISaleRoDetail,
    ISaleRoFields,
    ISaleRoList,
    Detailed,
    IInvalidate,
    ISaleSoDetailsSearch,
    ISaleSoDetails,
    ISaleRoPoSettleAndWhConfirm,
    ISaleRoSettleParams
} from "./saleRoTypes"
import { IPage } from "@/types/AppTypes";

class SaleRoService {
    async page(searchVo: ISaleRoSearch) {
        const apiUrl = "/bcm/sreturn/findPage";
        return await httpRequest.post<ISaleRoList>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/bcm/sreturn/findById/${id}`;
        return await httpRequest.get<ISaleRoDetail>(apiUrl);
    }

    async insert(insertVo: ISaleRoFields) {
        const apiUrl = "/bcm/sreturn/insert";
        return await httpRequest.post<ISaleRoDetail>(apiUrl, insertVo);
    }
    async update(updateVo: ISaleRoFields) {
        const apiUrl = "/bcm/sreturn/update";
        return await httpRequest.post<boolean>(apiUrl, updateVo);
    }
    //明细删除
    async materialDelete(params: Detailed) {
        const apiUrl = "/bcm/sreturn/materialDelete";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //导出
    async export(params: ISaleRoFields) {
        const apiUrl = "/bcm/sreturn/export";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
    //转销售单
    async transfer(planCode: string) {
        const apiUrl = `/bcm/sreturn/transfer/${planCode}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    // //详情导入
    // async invalidate(){
    //     const apiUrl = "/bcm/sreturn/detail/downloadTpl";
    //     return await httpRequest.getFile<boolean>(apiUrl);
    // }
    //下载模板
    async downloadTpl() {
        const apiUrl = "/bcm/sreturn/detail/downloadTpl";
        return await httpRequest.getFile<boolean>(apiUrl);
    }
    //作废
    async invalidate(params: IInvalidate) {
        const apiUrl = "/bcm/sreturn/invalidate";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //库房确认
    async warehousing(params: ISaleRoPoSettleAndWhConfirm) {
        const apiUrl = "/bcm/sreturn/warehousing";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //销售结算
    async settle(params: ISaleRoSettleParams) {
        const apiUrl = "/bcm/sreturn/settle";
        return await httpRequest.post<boolean>(apiUrl, params);
    }
    //销售退货查询详情
    async saleDetails(params: ISaleSoDetailsSearch) {
        const apiUrl = "/bcm/sale/details/findPage";
        return await httpRequest.post<IPage<ISaleSoDetails>>(apiUrl, params);
    }
    //详情导出
    async detailsExport(params: ISaleRoFields) {
        const apiUrl = "/bcm/sreturn/detailsExport";
        return await httpRequest.postFile(apiUrl, params, { timeout: 300000 });
    }
}

export const saleRoService = new SaleRoService();
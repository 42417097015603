import { ScmSalePrint } from "../../print/scm/scmSale/ScmSalePrint";
import { ScmPurcharseReturnPrint } from "../../print/scm/scmPurcharseReturn/ScmPurcharseReturnPrint";

import { BcmSalePrint } from "../../print/bcm/bcmSale/BcmSalePrint";
import { BcmPurcharseReturnPrint } from "../../print/bcm/bcmPurcharseReturn/BcmPurcharseReturnPrint";
import { BcmPresalePrint } from "../../print/bcm/bcmPresale/BcmPresalePrint";

import { VrmSalePrint } from "../../print/vrm/vrmSale/VrmSalePrint";
import { VrmPurcharseReturnPrint } from "../../print/vrm/vrmPurcharseReturn/VrmPurcharseReturnPrint";
import { VrmMaintenancePrint } from "../../print/vrm/vrmMaintenance/VrmMaintenancePrint";

import { CiSalePrint } from "../../print/ci/ciSale/CiSalePrint";
import { CiPurcharseReturnPrint } from "../../print/ci/ciPurcharseReturn/CiPurcharseReturnPrint";
import { CiMaintenancePrint } from "../../print/ci/ciMaintenance/CiMaintenancePrint";


export const PrintModal = () => {

    return (
        <>
            <ScmSalePrint />
            <ScmPurcharseReturnPrint />

            <BcmSalePrint />
            <BcmPurcharseReturnPrint />
            <BcmPresalePrint />

            <VrmSalePrint />
            <VrmPurcharseReturnPrint />
            <VrmMaintenancePrint />

            <CiSalePrint />
            <CiPurcharseReturnPrint />
            <CiMaintenancePrint />

        </>
    )
}
